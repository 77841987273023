import React from "react";
import "./about.css";

function About() {
  return (
    <div>
      <div className="main-container">
        <img src="../aboutbar.png" alt="bar pic" className="about-topbarIMG" />
        <div className="about-containerParent">
          <div className="about-containerChild1">
            <h1 className="about-heading">Welcome to Shaadmani.pk</h1>
            <p className="about-paragraph">
              where finding your perfect life partner is just a few clicks away.
              Our platform offers a unique opportunity for individuals to create
              personalized profiles and connect with like-minded individuals
              through virtual sessions and event based meetups.Join our
              Shaadmani.pk today and take the first step towards finding your
              perfect life partner
            </p>
          </div>
          <div className="about-containerChild2">
            <img
              src="../couple2.png"
              alt="bar pic"
              className="about-coupleIMG"
            />
          </div>
        </div>
        <img
          src="../aboutbardown.png"
          alt="bar pic"
          className="about-bottombarIMG "
        />
      </div>
    </div>
  );
}

export default About;
