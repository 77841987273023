import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAccount } from "../../redux/auth/actionCreator";
import "./Signup.css";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import validationSchema from "../../utils/schemas/validationSchema";
import InputFieldSmp from "../../components/FormComponents/InputFieldSmp";

const initialValues = {
  email: "",
  password: "",
  userName: "",
  fullName: "",
  confirmPassword: "",
};

function Signup() {
  const [details, setDetails] = useState(initialValues);
  const [error, setError] = useState("");

  const handlechange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    console.log(details);
  };

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const handleSubmit = async () => {
    console.log(details);
    if (details.password !== details.confirmPassword) {
      return alert("password and confirm password does not match!");
    }
    dispatch(createAccount(details));
  };

  useEffect(() => {
    if (auth.err) {
      if (auth.err.response?.status == "400") {
        setError("Email already exists!");
      }
    }
  }, [auth]);
  console.log("auth error", auth.err);

  return (
    <div className="container">
      <div className="image-side">
        <img src="./login1.png" alt="error" />
      </div>

      <div className="form-page">
        <div className="main-form">
          <h2>CREATE AN ACCOUNT</h2>
          <Formik
            initialValues={details}
            enableReinitialize={true}
            validationSchema={validationSchema.signUp}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className="form">
                <InputFieldSmp
                  classNameMain=""
                  classNameInput="fields"
                  value={details.userName}
                  name="userName"
                  type="text"
                  placeholder="Your UserName ?"
                  onChange={handlechange}
                  hasError={touched.userName && errors.userName}
                  errorMessage={errors.userName}
                />
                <InputFieldSmp
                  classNameInput="fields"
                  value={details.fullName}
                  name="fullName"
                  type="text"
                  placeholder="Your Full Name ?"
                  onChange={handlechange}
                  hasError={touched.fullName && errors.fullName}
                  errorMessage={errors.fullName}
                />
                <InputFieldSmp
                  classNameMain=""
                  classNameInput="fields"
                  value={details.email}
                  name="email"
                  type="text"
                  placeholder="email@abc.com"
                  onChange={handlechange}
                  hasError={touched.email && errors.email}
                  errorMessage={errors.email}
                />
                {error ? (
                  <p style={{ color: "red", margin: "0px" }}>
                    Email already exists!
                  </p>
                ) : (
                  ""
                )}

                <InputFieldSmp
                  classNameInput="fields"
                  value={details.password}
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={handlechange}
                  hasError={touched.password && errors.password}
                  errorMessage={errors.password}
                />
                <InputFieldSmp
                  classNameInput="fields"
                  value={details.confirmPassword}
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  onChange={handlechange}
                  hasError={touched.confirmPassword && errors.confirmPassword}
                  errorMessage={errors.confirmPassword}
                />

                <p>
                  By clicking the <span>Sign up </span> you agree to the terms
                  and conditions
                </p>

                <button className="btn" type="submit">
                  {auth.loading ? (
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={true}
                    />
                  ) : (
                    "Sign up"
                  )}
                </button>
                <p style={{ textAlign: "center", fontSize: "12px" }}>
                  Already have an account?{" "}
                  <Link to="/signin">
                    <span>Sign in Now</span>
                  </Link>
                </p>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Signup;
