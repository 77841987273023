import React, { useEffect, useState } from "react";
import "./Forms.css";
import validationSchema from "../../utils/schemas/validationSchema";
import { Formik, Form } from "formik";
import { RotatingLines } from "react-loader-spinner";
import InputField from "../FormComponents/InputField";
import { useDispatch, useSelector } from "react-redux";
import { personalDetails } from "../../utils/api";
import { getUserAction } from "../../redux/user/actionCreator";
import { useNavigate } from "react-router-dom";

const initialValues = {
  artsAndCulture: "",
  foodAndDrink: "",
  activism: "",
  diet: "",
  technology: "",
  outdoor: "",
  sports: "",
};

function InterestDetailsForm() {
  const [details, setDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const handlechange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    console.log(details);
  };

  const user = useSelector((state) => state.user.user);
  console.log("user", user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAction());
  }, [dispatch]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    personalDetails({ ...details, id: user._id })
      .then(({ data }) => {
        console.log("data:", data);
        navigate("/qualification-detail");
      })
      .catch((e) => console.log(e));
    setLoading(false);
  };

  useEffect(() => {
    if (user.user?.outdoor) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <div className="formContainer">
      <div className="mainDivForm">
        <h1>Interests</h1>
        <Formik
          initialValues={details}
          enableReinitialize={true}
          validationSchema={validationSchema.interestDetails}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="maindivforDivision">
                <div className="formSeperation">
                  <InputField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formInput"
                    label="Arts & culture"
                    value={details.artsAndCulture}
                    name="artsAndCulture"
                    type="text"
                    placeholder="Your Interests ?"
                    onChange={handlechange}
                    hasError={touched.artsAndCulture && errors.artsAndCulture}
                    errorMessage={errors.artsAndCulture}
                  />
                  <InputField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formInput"
                    label="Food & Drink"
                    value={details.foodAndDrink}
                    name="foodAndDrink"
                    type="text"
                    placeholder="Food You Like ?"
                    onChange={handlechange}
                    hasError={touched.foodAndDrink && errors.foodAndDrink}
                    errorMessage={errors.foodAndDrink}
                  />
                  <InputField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formInput"
                    label="Activism / Causes"
                    value={details.activism}
                    name="activism"
                    type="text"
                    placeholder="Activism / Causes ?"
                    onChange={handlechange}
                    hasError={touched.activism && errors.activism}
                    errorMessage={errors.activism}
                  />
                  <InputField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formInput"
                    label="Diet"
                    value={details.diet}
                    name="diet"
                    type="text"
                    placeholder="Your Diet ?"
                    onChange={handlechange}
                    hasError={touched.diet && errors.diet}
                    errorMessage={errors.diet}
                  />
                </div>
                <div className="formSeperation">
                  <InputField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formInput"
                    label="Technology"
                    value={details.technology}
                    name="technology"
                    type="text"
                    placeholder="Technology you are interested in ?"
                    onChange={handlechange}
                    hasError={touched.technology && errors.technology}
                    errorMessage={errors.technology}
                  />
                  <InputField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formInput"
                    label="Outdoor"
                    value={details.outdoor}
                    name="outdoor"
                    type="text"
                    placeholder="Outdoor games you like ?"
                    onChange={handlechange}
                    hasError={touched.outdoor && errors.outdoor}
                    errorMessage={errors.outdoor}
                  />
                  <InputField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formInput"
                    label="Sports"
                    value={details.sports}
                    name="sports"
                    type="text"
                    placeholder="Sports you like ?"
                    onChange={handlechange}
                    hasError={touched.sports && errors.sports}
                    errorMessage={errors.sports}
                  />
                </div>
              </div>
              <div className="buttonDiv">
                <button className="formButton" type="submit">
                  {loading ? (
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={true}
                    />
                  ) : (
                    "save"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default InterestDetailsForm;
