import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = ({ scrollToSection, home, about, contactus }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav className="upDate_navbar">
        <div className="upDate_navbarContainer">
          <div className="upDate_logoContainer">
            <img src="logo.png" alt="Logo" className="upDate_logo" />
          </div>

          <div
            onClick={toggleMenu}
            className={`hamburger ${isOpen ? "open" : ""}`}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>

          <ul className={`upDate_menuBar ${isOpen ? "open" : ""}`}>
            <Link style={{ textDecoration: "none", color: "black" }} to="/">
              <li
                onClick={() => {
                  scrollToSection(home);
                  setIsOpen(false); // Close the menu when an item is clicked
                }}
                className="upDate_menuBarItems"
              >
                Home
              </li>
            </Link>
            <li
              onClick={() => {
                scrollToSection(about);
                setIsOpen(false);
              }}
              className="upDate_menuBarItems"
            >
              About Us
            </li>
            <li
              onClick={() => {
                scrollToSection(contactus);
                setIsOpen(false);
              }}
              className="upDate_menuBarItems"
            >
              Contact Us
            </li>
            <li>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="signin"
              >
                <button className="upDate_SigninButton">Sign in</button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="upDate_Barr"></div>
      </nav>
    </div>
  );
};

export default Navbar;
