import React, { useState } from "react";
import "./EventPopup.css";
import { UilTimesCircle } from "@iconscout/react-unicons";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { addpayment } from "../../utils/api";

function EventPopup({
  show,
  setShow,
  eventId,
}) {
  const [uploadImage, setUploadImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(eventId);
  console.log("id", id)
  
  const cloudinaryUploadImage = async () => {
    const form = new FormData();
    form.append("file", uploadImage);
    form.append("upload_preset", "shaadmani");
    form.append("cloud_name", "dtib2nmzi");
    const { data } = await axios.post(
      "https://api.cloudinary.com/v1_1/dtib2nmzi/image/upload",
      form,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    console.log("data", data);
    return data.secure_url;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("upload image", uploadImage)
    const source_URL_Image = await cloudinaryUploadImage();
    // console.log("source", source_URL_Image);
    addpayment( eventId, {
      paymentscreenShot: source_URL_Image, 
    })
      .then(({ data }) => {
        setLoading(false);
        setUploadImage("");
        setShow(false);
        console.log("data: 42", data);
      })
      .catch((e) => console.log(e));
     
    alert("Your booking is being processed, we will notify you once the event is booked");
  };

  return show ? (
    <form className="main-popup" onSubmit={handleSubmit}>
      <div className="event-popup">
        <span className="cross-btn" onClick={() => setShow(false)}>
          <UilTimesCircle />
        </span>
        <h3 className="account-head">Shaadmani Account Details</h3>
        <div className="detail">
          <div className="account-details">
            <p>Bank Account</p>
            <p>JazzCash Account</p>
            <p>EasyPaisa Account</p>
            <p>Amount</p>
          </div>
          <div className="account-number">
            <p>235729385893124982</p>
            <p>235729385893124982</p>
            <p>235729385893124982</p>
            <p>Amount</p>
          </div>
        </div>
        <div>
          <h3 className="attach-image">Attach Payment Screenshot </h3>
          <input
            type="file"
            id="ss"
            style={{ display: "none" }}
            onChange={(e) => setUploadImage(e.target.files[0])}
          />
          <label htmlFor="ss" className="screenshot">
            <span>
              <svg
                width="30"
                height="30"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.09665 7.74893C2.08203 8.43537 2.08203 9.20864 2.08203 10.0834V12.5H10.5165C10.1698 12.3368 9.7906 12.2292 9.38968 12.1891L7.29036 11.9792H7.29035C5.78031 11.8282 5.02529 11.7527 4.42291 11.4781C3.42014 11.021 2.65038 10.1704 2.29532 9.12713C2.16932 8.75689 2.11775 8.34056 2.09665 7.74893ZM22.7895 5.85791L22.3945 17.7084C21.3941 17.7084 20.4346 17.3109 19.7272 16.6035L18.5532 15.4295C18.1127 14.9891 17.8925 14.7688 17.6632 14.6368C17.0455 14.2812 16.2852 14.2812 15.6675 14.6368C15.4382 14.7688 15.218 14.9891 14.7775 15.4295L13.9722 16.2349C13.4284 16.7786 12.4987 16.3935 12.4987 15.6245V22.9167H14.9154C18.6866 22.9167 20.5722 22.9167 21.7438 21.7451C22.9154 20.5735 22.9154 18.6879 22.9154 14.9167V10.0834C22.9154 8.29078 22.9154 6.92424 22.7895 5.85791Z"
                  fill="#D21285"
                />
                <path
                  d="M3.08203 10.0833C3.08203 8.16936 3.08415 6.83455 3.21956 5.82741C3.3511 4.84905 3.59169 4.33095 3.96071 3.96193C4.32973 3.59291 4.84782 3.35232 5.82619 3.22078C6.83333 3.08538 8.16814 3.08325 10.082 3.08325H14.9154C16.8293 3.08325 18.1641 3.08538 19.1712 3.22078C20.1496 3.35232 20.6677 3.59291 21.0367 3.96193C21.4057 4.33095 21.6463 4.84905 21.7778 5.82741C21.9132 6.83455 21.9154 8.16936 21.9154 10.0833V14.9166C21.9154 16.8305 21.9132 18.1653 21.7778 19.1724C21.6463 20.1508 21.4057 20.6689 21.0367 21.0379C20.6677 21.4069 20.1496 21.6475 19.1712 21.7791C18.1641 21.9145 16.8293 21.9166 14.9154 21.9166H13.4987V14.4999C13.4987 14.4818 13.4987 14.4636 13.4987 14.4455C13.4988 14.0214 13.4989 13.6093 13.4532 13.2694C13.4017 12.8862 13.2761 12.4489 12.9129 12.0857C12.5497 11.7225 12.1124 11.597 11.7292 11.5454C11.3893 11.4997 10.9772 11.4998 10.5531 11.4999C10.535 11.4999 10.5169 11.4999 10.4987 11.4999H3.08203V10.0833Z"
                  stroke="#D21285"
                  stroke-width="2"
                />
                <circle cx="16.6654" cy="8.33333" r="2.08333" fill="#D21285" />
                <path
                  d="M8.33203 16.6667V15.6667H9.33203V16.6667H8.33203ZM3.74839 21.6143C3.31713 21.9593 2.68784 21.8894 2.34283 21.4581C1.99782 21.0268 2.06774 20.3976 2.499 20.0525L3.74839 21.6143ZM7.33203 21.8751V16.6667H9.33203V21.8751H7.33203ZM8.33203 17.6667H3.1237V15.6667H8.33203V17.6667ZM8.95673 17.4476L3.74839 21.6143L2.499 20.0525L7.70734 15.8859L8.95673 17.4476Z"
                  fill="#D21285"
                />
              </svg>
            </span>
            <p className="upload">
              {uploadImage ? uploadImage.name : "Upload or drag and drop an image to use as an input"}
              
            </p>
          </label>
        </div>
        <div  className="buttons">
          <button className="confirm" type="submit">
            {loading ? (
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="15"
                visible={true}
              />
            ) : (
              "Confirm"
            )}
          </button>
          <button className="cancel" onClick={() => setShow(false)}>
            Cancel
          </button>
        </div>
      </div>
    </form>
  ) : (
    ""
  );
}

export default EventPopup;
