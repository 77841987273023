import React from "react";
import "./features.css";
import Typewriter from "typewriter-effect";

function Features() {
  return (
    <div>
      <div className="featureMain-container">
        <div className="features-bar">
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: "8px 0 0 8px",
              position: "relative",
            }}
          >
            <svg
              style={{ paddingLeft: "15px" }}
              class="svg-icon search-icon"
              aria-labelledby="title desc"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 19.9 19.7"
            >
              <title id="title">Search Icon</title>
              <desc id="desc">A magnifying glass icon.</desc>
              <g class="search-path" fill="none" stroke="#848F91">
                <path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4" />
                <circle cx="8" cy="8" r="7" />
              </g>
            </svg>
            <span className="features-typer">
              <Typewriter
                options={{
                  strings: [
                    "YOUR SEARCH FOR THE PERFECT RISHTA ENDS WITH SHAADMANI",
                    "WHERE TRADITIONS MEETS MODERNITY IN RISHTA FINDING",
                    "LOOKING FOR RISHTA LET SHAADMANI GUIDE YOU",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </div>
        </div>
        <img
          src="../couple3.png"
          alt=""
          height="245px"
          width="160px"
          className="features-sideCouple"
        />
        <h2 className="features-text">How the system works</h2>
        <div className="features-containerParent">
          <div className="features-containerChild1">
            <div className="features-sidebar">
              <div className="icon">
                <div className="img1">
                  <svg
                    width="34"
                    height="37"
                    viewBox="0 0 34 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1 0C0.447715 0 0 0.447714 0 0.999999V7.30719H33.2284V1C33.2284 0.447715 32.7807 0 32.2284 0H1ZM33.2284 9.30719H0V9.47633C0 10.0531 0.24896 10.6017 0.68299 10.9815L15.2972 23.7689C16.0512 24.4287 17.1771 24.4287 17.9312 23.7689L32.5454 10.9815C32.9794 10.6017 33.2284 10.0531 33.2284 9.47633V9.30719Z"
                      fill="#A81A3A"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.6133 34.6252L18.6133 16.6141H14.6133V36.6252L18.6133 34.6252Z"
                      fill="#A81A3A"
                    />
                  </svg>
                </div>
                <div className="icon-text">
                  AI integration to filter and match profiles
                </div>
              </div>
              <div className="icon">
                <div className="img1">
                  <svg
                    width="38"
                    height="38"
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19 37.75C29.3553 37.75 37.75 29.3553 37.75 19C37.75 8.64466 29.3553 0.25 19 0.25C8.64466 0.25 0.25 8.64466 0.25 19C0.25 29.3553 8.64466 37.75 19 37.75ZM14.5328 9.57376L29.9265 18.1258C30.6123 18.5068 30.6123 19.4932 29.9265 19.8742L14.5328 28.4262C13.7329 28.8706 12.75 28.2922 12.75 27.3773V10.6228C12.75 9.70777 13.7329 9.12941 14.5328 9.57376Z"
                      fill="#AE0909"
                    />
                  </svg>
                </div>
                <div className="icon-text">
                  Breakout rooms for hosting the virtual activities
                </div>
              </div>
              <div className="icon">
                <div className="img1">
                  <svg
                    width="38"
                    height="30"
                    viewBox="0 0 38 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.250698 5.66648C0.415076 5.66645 0.581761 5.70702 0.735643 5.79251L19 15.9394L37.2644 5.79251C37.4182 5.70702 37.5849 5.66645 37.7493 5.66649C37.7425 3.33088 37.6686 2.09265 36.8713 1.29535C35.9926 0.416666 34.5784 0.416666 31.75 0.416666H6.25C3.42157 0.416666 2.00736 0.416666 1.12868 1.29535C0.33138 2.09265 0.257537 3.33087 0.250698 5.66648ZM37.75 7.81062L19.9713 17.6877C19.3672 18.0233 18.6328 18.0233 18.0287 17.6877L0.25 7.81063V23.5833C0.25 26.4118 0.25 27.826 1.12868 28.7047C2.00736 29.5833 3.42157 29.5833 6.25 29.5833H31.75C34.5784 29.5833 35.9926 29.5833 36.8713 28.7047C37.75 27.826 37.75 26.4118 37.75 23.5833V7.81062Z"
                      fill="#9A0000"
                    />
                  </svg>
                </div>
                <div className="icon-text">
                  Events email taking place in your citys
                </div>
              </div>
            </div>
          </div>
          <div className="about-containerChild2">
            <img src="../vector.png" alt="" className="features-IMG" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
