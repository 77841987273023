import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { getUserAction } from "../../redux/user/actionCreator";
import Sidebar from "../sidebar/Sidebar";
import "./DashboardLayout.css";

function DashboardLayout() {
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state?.user);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.user?.weight && !user.loading) {
      console.log("weight", user.user?.weight);
      navigate("/personal-details");
    } else if (!user.user?.religion && !user.loading) {
      console.log("religion", user.user?.religion);
      navigate("/religion-detail");
    } else if (!user.user?.outdoor && !user.loading) {
      console.log("outdoor", user.user?.outdoor);
      navigate("/interest-detail");
    } else if (!user.user?.jobTitle && !user.loading) {
      console.log("job", user.user?.jobTitle);
      navigate("/qualification-detail");
    }
    // else navigate("/dashboard");
  }, [user]);

  // console.log("age", user.user?.age);
  // console.log("religion", user.user?.religion);
  // console.log("age", user.user?.age);

  // useEffect(() => {
  //   if (!user.user?.age && !user.loading) {
  //     navigate("/personal-details");
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (!user.user?.religion && !user.loading) {
  //     navigate("/religion-detail");
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (!user.user?.outdoor && !user.loading) {
  //     navigate("/interest-detail");
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (!user.user?.language && !user.loading) {
  //     navigate("/qualification-detail");
  //   }
  // }, [user]);

  useEffect(() => {
    if (!auth.token) {
      navigate("/");
    } else {
      dispatch(getUserAction());
    }
  }, [auth, navigate, dispatch]);

  return (
    <div style={{ display: "flex", overflow: "hidden" }}>
      <Sidebar />
      <div className="outlet">
        <Outlet />
      </div>
    </div>
  );
}

export default DashboardLayout;
