import React, { useRef, useEffect, useState } from "react";
import Card from "../../components/Card/Card";
import Card2 from "../../components/Card2/Card2";
import Card3 from "../../components/Card3/Card3";
import Card4 from "../../components/Card4/Card4";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import ReviewCard from "../../components/ReviewCard/ReviewCard";
import "./Home.css";
import Typewriter from "typewriter-effect";
import ReviewCard2 from "../../components/ReviewCard2/ReviewCard2";
import ReviewCard3 from "../../components/ReviewCard3/ReviewCard3";
import { Link } from "react-router-dom";
import About from "../../components/HomeComponents/AboutUs/About";
import Features from "../../components/HomeComponents/Features/Features";
import LandingPage from "../../components/HomeComponents/LandingPage/LandingPage";

const Home = () => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const home = useRef(null);
  const about = useRef(null);
  const contactus = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    setIsDisplayed(true);
  }, []);

  return (
    <div className={`fade-in ${isDisplayed ? "fade-in-active" : ""}`}>
      <div style={{ overflowX: "hidden" }}>
        <Navbar
          scrollToSection={scrollToSection}
          home={home}
          about={about}
          contactus={contactus}
        />
        <div ref={home}>
          <LandingPage />
        </div>

        <div ref={about}>
          <About />
        </div>

        <div>
          <Features />
        </div>

        <div className="reviews">
          <h2 className="heading">FAQs</h2>
          <div className="review-card">
            <ReviewCard />
            <ReviewCard2 />
            <ReviewCard3 />
          </div>
        </div>
        <div ref={contactus}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
