import React, { useEffect, useState } from "react";
import EventCard from "../../components/EventCard/EventCard";
import "./Events.css";
import { Link } from "react-router-dom";
import { getAllEvents } from "../../utils/api";
import Loading from "../../components/Loading/Loading";
import profileimg from "../../assets/profile4.png";
import { useSelector } from "react-redux";
function Events() {
  const [allEvents, setallEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    getAllEvents()
      .then(({ data }) => {
        setallEvents(data);
        setIsLoading(false);
      })
      .catch((e) => console.log(e));
  }, []);

  console.log("events", allEvents);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            className="event-head"
          >
            <h2>Events</h2>
            <img
              style={{
                borderRadius: "30px",
                marginRight: "2rem",
                marginTop: "2rem",
              }}
              src={user?.profileImage}
              height="50px"
              width="50px"
              alt="abc"
            />
          </div>
          <div className="event-cards">
            {allEvents.map((events) => (
              <Link to={`${events._id}`} key={events._id}>
                <EventCard events={events} />
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Events;
