import React from "react";
import { useSelector } from "react-redux";
import "./ShowProfileimg.css";

function ShowProfileImg() {
  const user = useSelector((state) => state.user.user);
  return (
    <div className="pimg">
      <img
        src={user?.profileImage}
        alt=""
        height="100%"
        width="100%"
        style={{ borderRadius: "80px" }}
      />
    </div>
  );
}

export default ShowProfileImg;
