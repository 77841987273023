import React, { useState } from "react";
import "./Image.css";

function Image({ id, setImage, image, setChanged }) {

  return (
    <div className="img-card">
      {image ? (
        <>
          <img
            src={
              typeof image === "string"
                ? image
                : window.webkitURL.createObjectURL(image)
            }
            alt="profile picture"
            height="100%"
            width="100%"
            style={{ borderRadius: "30px" }}
          />
          <label
            htmlFor={id}
            // style={{
            //   background: "transparent",
            //   width: 150,
            //   height: 200,
              // position: "absolute",
            // }}
            className="label"
          />
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id={id}
            onChange={(e) => {
              setImage(e.target.files[0]);
              setChanged(true);
            }}
          />
        </>
      ) : (
        <>
          <label htmlFor={id} className="plus-icon">
            +
          </label>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id={id}
            onChange={(e) => {
              setImage(e.target.files[0]);
              setChanged(true);
            }}
          />
        </>
      )}
    </div>
  );
}

export default Image;
