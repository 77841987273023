import React from "react";
import InterestDetailsForm from "../../components/Forms/InterestDetailsForm";

function InterestsDetails() {
  return (
    <div>
      <InterestDetailsForm />
    </div>
  );
}

export default InterestsDetails;
