import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ShowQualification.css";
import { getUserAction } from "../../redux/user/actionCreator";
import { personalDetails } from "../../utils/api";
import ShowProfileImg from "../../components/ShowProfileImg/ShowProfileImg";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Loading from "../../components/Loading/Loading";

function ShowQualification() {
  const [isLoading, setIsLoading] = useState(true);

  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const [updateDetails, setUpdateDetails] = useState({ user });
  useEffect(() => {
    setUpdateDetails({
      educationLevel: user?.educationLevel,
      employementStatus: user?.employementStatus,
      language: user?.language,
      technology: user?.technology,
      jobTitle: user?.jobTitle,
    });
    setIsLoading(false);
  }, [user, refresh]);

  const update = async (e) => {
    personalDetails({ ...updateDetails, id: user?._id })
      .catch((e) => console.log(e))
      .then(() => {
        setRefresh((r) => !r);
        setShow(false);
        dispatch(getUserAction());
        Swal.fire({
          title: "Data Updated Successfully !",
          icon: "success",
          confirmButtonColor: "#D21285",
          confirmButtonText: "OK",
          iconColor: "#D21285",
          background: "#FFE4F4",
          customClass: {
            container: "my-swal-border",
          },
        });
      });
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          {" "}
          <div>
            <h2 className="h2">Profile</h2>
            <ShowProfileImg />
          </div>
          <div className="religion-details">
            <h3>Qualification Details</h3>
            <div className="details1">
              <div style={{ display: "flex" }}>
                <div className="sub-details">
                  <p>Education level</p>
                  <p>Employment</p>
                </div>
                <div className="sub-details3">
                  {show ? (
                    <select
                      name=""
                      id=""
                      onChange={(e) =>
                        setUpdateDetails({
                          ...updateDetails,
                          educationLevel: e.target.value,
                        })
                      }
                    >
                      <option value="">Enter Education Level</option>
                      <option value="matric">Matric</option>
                      <option value="intermediate">Intermediate</option>
                      <option value="BS">BS</option>
                      <option value="MS">MS</option>
                    </select>
                  ) : (
                    <p>{user?.educationLevel}</p>
                  )}
                  {show ? (
                    <select
                      name=""
                      id=""
                      value={updateDetails?.employementStatus}
                      onChange={(e) =>
                        setUpdateDetails({
                          ...updateDetails,
                          employementStatus: e.target.value,
                        })
                      }
                    >
                      <option value="">Enter Employement Status</option>
                      <option value="employeed">Employeed</option>
                      <option value="unemployeed">UnEmployeed</option>
                    </select>
                  ) : (
                    <p>{user?.employementStatus}</p>
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="sub-details">
                  <p>Language</p>
                  <p>Job title</p>
                </div>
                <div className="sub-details3">
                  {show ? (
                    <select
                      name=""
                      id=""
                      value={updateDetails?.language}
                      onChange={(e) =>
                        setUpdateDetails({
                          ...updateDetails,
                          language: e.target.value,
                        })
                      }
                    >
                      <option value="">Enter language</option>
                      <option value="urdu">Urdu</option>
                      <option value="english">English</option>
                      <option value="punjabi">Punjabi</option>
                      <option value="pushto">Pushto</option>
                    </select>
                  ) : (
                    <p>{user?.language}</p>
                  )}
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails?.jobTitle}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          jobTitle: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.jobTitle}</p>
                  )}
                </div>
              </div>
            </div>
            <button onClick={() => setShow(true)}>
              {show ? <p onClick={update}>Update</p> : "Edit"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShowQualification;
