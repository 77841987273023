import { ErrorMessage } from "formik";
import React from "react";

const TextAreaField = ({
  label,
  name,
  type,
  classNameInput,
  classNameLabel,
  classNameMain,
  value,
  onChange,
  placeholder,
  hasError,
  errorMessage,
  cols,
  rows,
  x,
}) => (
  <div className={classNameMain}>
    <label className={classNameLabel} htmlFor={name}>
      {label}:
    </label>
    <textarea
      cols={cols}
      rows={rows}
      className={`input ${classNameInput}`}
      type={type}
      id={name}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />

    {hasError && (
      <div>
        <p style={{ color: "red", margin: "3px 0 3px 0" }}>{errorMessage}</p>
      </div>
    )}
  </div>
);

export default TextAreaField;
