import { ErrorMessage } from "formik";
import React from "react";

const InputField = ({
  label,
  name,
  type,
  classNameInput,
  classNameLabel,
  classNameMain,
  value,
  onChange,
  placeholder,
  hasError,
  errorMessage,
  x,
  noLabel,
  classNameWithoutLabel,
}) => (
  <div className={classNameMain}>
    {!noLabel ? (
      <label className={classNameLabel} htmlFor={name}>
        {label}:
      </label>
    ) : (
      <div className={classNameWithoutLabel}>{/* Content without label */}</div>
    )}
    <input
      className={`input ${classNameInput}`}
      type={type}
      id={name}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    {hasError && (
      <div>
        <p style={{ color: "red", margin: "3px 0 3px 0" }}>{errorMessage}</p>
      </div>
    )}
  </div>
);

export default InputField;
