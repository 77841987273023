import React, { useRef } from "react";
import "./About.css";
import Card from "../../components/Card/Card";
import Card2 from "../../components/Card2/Card2";
import Card3 from "../../components/Card3/Card3";
import Card4 from "../../components/Card4/Card4";
import "./nav.css";
import { useNavigate } from "react-router-dom";
import { UilPrevious } from "@iconscout/react-unicons";

function About() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="about-main1">
      <nav className="navbar">
        <div className="logo-container">
          <img src="logo.png" alt="Logo" className="logo" />
        </div>

        <div onClick={goBack}>
          <UilPrevious className="back-btn" />
        </div>
      </nav>

      <h2 className="about2">About us</h2>

      <div className="aboutus">
        {" "}
        <p className="about">
          Welcome to Shaadmani.pk where finding your perfect life partner is
          just a few clicks away. Our platform offers a unique opportunity for
          individuals to create personalized profiles and connect with
          like-minded individuals through virtual sessions and event based
          meetups.
        </p>
        <p className="about">
          Creating a profile on our website is easy and takes just a few
          minutes. Once you create a profile, you can customize it with your
          personal details, preferences, and expectations from a life partner.
          You can also upload photos and share your interests and hobbies to
          make your profile stand out.
        </p>
        <p className="about">
          Once your profile is complete, our intelligent matchmaking algorithm
          will use your preferences and search criteria to suggest potential
          matches.
        </p>
        <p className="about">
          Our platform also offers several interactive features, including
          virtual sessions and meetups, to help you connect with other members
          and build meaningful relationships. Our virtual sessions may include
          webinars, online workshops, and virtual matchmaking events that allow
          you to connect with potential partners from the comfort of your own
          home.
        </p>
        <p className="about">
          For those who prefer to meet in person, we organize regular meetups in
          various locations around Pakistan. These meetups offer a chance to
          meet other members and build real-life connections in a safe and
          welcoming environment.
        </p>
        <p className="about">
          We take privacy and security very seriously and ensure that all member
          profiles are verified before being made available on our platform. Our
          team is always available to assist you with any queries or concerns
          you may have, and we strive to provide a seamless and enjoyable
          experience for all our members.
        </p>
      </div>
      <div className="cards">
        <Card />
        <Card2 />
        <Card3 />
        <Card4 />
      </div>
      <div className="remarks">
        Join our Shaadmani.pk today and take the first step towards finding your
        perfect life partner.
      </div>
    </div>
  );
}

export default About;
