import React from "react";
import "./EventCard.css";

function EventCard({ events }) {
  return (
    <div className="sub-card">
      <img src={events.eventThumbnail} alt="" height="100%" width="100%" />
      <div className="event-address">
        <p className="add-text">{events.cityName}</p>
        <div className="address">
          <p>
            {events.eventDate} <br /> {events.eventTime}
          </p>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
