import React, { useEffect, useState } from "react";
import Signup from "./pages/Signup/Signup";
import Signin from "./pages/Signin/Signin";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthLayout from "./Layout/AuthLayout/AuthLayout";
import DashboardLayout from "./Layout/DashboardLayout/DashboardLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import Home from "./pages/Home/Home";
import Multimedia from "./pages/multimedia/Multimedia";
import Events from "./pages/Events/Events";
import EventDetails from "./pages/EventDetails/EventDetails";
import ShowReligiousDetails from "./pages/showReligiousDetails/ShowReligiousDetails";
import ShowInterest from "./pages/ShowInterest/ShowInterest";
import ShowQualification from "./pages/ShowQualification/ShowQualification";
import ShowSpouseRequirements from "./pages/showSpouseRequirements/ShowSpouseRequirements";
import About from "./pages/About us/About";
import InterestsDetails from "./pages/InterestsDetails/InterestsDetails";
import SpouseRequiremts from "./pages/SpouseRequirements/SpouseRequiremts";
import QualificationsDetails from "./pages/QualificationsDetails/QualificationsDetails";
import "./App.css";
import ReligiousDetails from "./pages/ReligiousDetails/ReligiousDetails";
import PersonalDetails from "./pages/PersonalDetails/PersonalDetails";
import SocialLinks from "./pages/SocialLinks/SocialLinks";

const SplashScreen = () => {
  const [showSplash, setShowSplash] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSplash(false);
    }, 1500);
  }, []);

  return showSplash ? (
    <div>
      <div className={`splash-screen ${showSplash ? "show" : "hide"}`}>
        <img
          style={{ height: "75px", width: "280px" }}
          src="logo.png"
          alt="Logo"
          className="logo"
        />
      </div>
    </div>
  ) : (
    <div>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="aboutus" element={<About />} />

          <Route element={<AuthLayout />}>
            <Route path="signup" element={<Signup />} />
            <Route path="signin" element={<Signin />} />
          </Route>
          <Route>
            <Route path="personal-details" element={<PersonalDetails />} />
            <Route path="religion-detail" element={<ReligiousDetails />} />
            <Route path="interest-detail" element={<InterestsDetails />} />
            <Route
              path="qualification-detail"
              element={<QualificationsDetails />}
            />
            <Route path="spouse-detail" element={<SpouseRequiremts />} />
          </Route>

          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route
              path="religious-details"
              element={<ShowReligiousDetails />}
            />
            <Route path="events">
              <Route index element={<Events />} />
              <Route path=":Id" element={<EventDetails />} />
            </Route>
            <Route path="pictures/multimedia" element={<Multimedia />} />
            <Route path="interests" element={<ShowInterest />} />
            <Route path="qualification" element={<ShowQualification />} />
            <Route path="social-links" element={<SocialLinks />} />

            <Route
              path="spouse-requirements"
              element={<ShowSpouseRequirements />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default SplashScreen;
