import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../redux/user/actionCreator";
import { getspouseDetailById, updateSpouseDetail } from "../../utils/api";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "./styleSheet.css";
import Loading from "../../components/Loading/Loading";
import cities from "../../components/Form/cities.json";
import { getAllCountries, getCities } from "../../utils/countries";

function ShowSpouseRequirements() {
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidValue, setInvalidValue] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [updateDetails, setUpdateDetails] = useState("");
  const [allCountries, setAllCountries] = useState([]);
  // const [cities, setAllCities] = useState([]);
  // const [country, setCountry] = useState("");

  useEffect(() => {
    setUpdateDetails({
      age: updateDetails?.age,
      height: updateDetails?.height,
      educationLevel: updateDetails?.educationLevel,
      location: updateDetails?.location,
      city: updateDetails?.city,
      maritalStatus: updateDetails?.maritalStatus,
      employementStatus: updateDetails?.employementStatus,
    });
    setIsLoading(false);
  }, [refresh]);

  // setCountry(updateDetails.location);

  useEffect(() => {
    getspouseDetailById()
      .then(({ data }) => {
        setUpdateDetails(data);
      })
      .catch((e) => console.log(e));
  }, [refresh]);
  console.log("plplpl", updateDetails);

  const validateFractionalNumber = (input) => {
    console.log("object");
    // Regular expression to match fractional numbers with two decimal places
    const regex = /^\d+(\.\d+)?$/;

    // Check if input matches the regular expression
    if (regex.test(input)) {
      console.log("trueee");
      setInvalidValue(false);
      return true; // Input is valid
    } else {
      console.log("FALSEEE");
      setInvalidValue(true);
      return false; // Input is invalid
    }
  };
  const update = async (e) => {
    if (invalidValue) return;

    setShow(false);
    setLoading(true);
    console.log("spouseupdateDetails", updateDetails);
    const response = updateSpouseDetail({
      ...updateDetails,
      id: updateDetails._id,
    })
      .catch((e) => console.log(e))
      .then(() => {
        setRefresh((r) => !r);
        setShow(false);
        setLoading(false);
        Swal.fire({
          title: "Data Updated Successfully !",
          icon: "success",
          confirmButtonColor: "#D21285",
          confirmButtonText: "OK",
          iconColor: "#D21285",
          background: "#FFE4F4",
          customClass: {
            container: "my-swal-border",
          },
        });
      });
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div className="religion-details">
            <h3
              style={{
                marginTop: "0px",
                paddingTop: "4rem",
                fontSize: "24px",
              }}>
              Spouse Details
            </h3>
            <div className="details1">
              <div style={{ display: "flex" }}>
                <div className="sub-details">
                  <p>Age From</p>
                  <p>Age To</p>
                  <p>Height From (feet)</p>
                  <p>Height From (inch) </p>
                  <p>Height To (feet)</p>
                  <p>Height To (inch) </p>
                </div>
                <div className="sub-details3">
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails.age.ageFrom}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          age: {
                            ...updateDetails.age,
                            ageFrom: e.target.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    <p>{updateDetails?.age?.ageFrom} &nbsp; Year's Old</p>
                  )}
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails.age.ageTo}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          age: {
                            ...updateDetails.age,
                            ageTo: e.target.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    <p>{updateDetails?.age?.ageTo} &nbsp; Year's Old</p>
                  )}
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails.height.feetFrom}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          height: {
                            ...updateDetails.height,
                            feetFrom: e.target.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    <p>{updateDetails?.height?.feetFrom} &nbsp; Feet</p>
                  )}
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails.height.inchesFrom}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          height: {
                            ...updateDetails.height,
                            inchesFrom: e.target.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    <p>{updateDetails?.height?.inchesFrom} &nbsp; Inches</p>
                  )}
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails.height.feetTo}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          height: {
                            ...updateDetails.height,
                            feetTo: e.target.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    <p>{updateDetails?.height?.feetTo} &nbsp; Feet</p>
                  )}
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails.height.inchesTo}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          height: {
                            ...updateDetails.height,
                            inchesTo: e.target.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    <p>{updateDetails?.height?.inchesTo} &nbsp; Inches</p>
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="sub-details">
                  <p>Qualification</p>
                  <p>Country</p>
                  <p>City</p>
                  <p>Marital Status</p>
                  <p>Employement Status</p>
                </div>
                <div className="sub-details3">
                  {show ? (
                    <select
                      name=""
                      id=""
                      onChange={(e) =>
                        setUpdateDetails({
                          ...updateDetails,
                          educationLevel: e.target.value,
                        })
                      }
                      className="select">
                      <option value="">Enter Education Level</option>
                      <option value="matric">Matric</option>
                      <option value="intermediate">Intermediate</option>
                      <option value="BS">BS</option>
                      <option value="MS">MS</option>
                    </select>
                  ) : (
                    <p>{updateDetails?.qualification}</p>
                  )}
                  {show ? (
                    <select
                      name=""
                      id=""
                      onChange={(e) =>
                        setUpdateDetails({
                          ...updateDetails,
                          location: e.target.value,
                        })
                      }
                      // className="select"
                    >
                      <option value="">Enter current location</option>
                      {cities.map((city) => (
                        <option value={city.name}>{city.name}</option>
                      ))}
                    </select>
                  ) : (
                    <p>{updateDetails?.location}</p>
                  )}
                  {show ? (
                    <input
                      style={{ marginBottom: "7px" }}
                      type="text"
                      value={updateDetails.city}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          city: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{updateDetails?.city}</p>
                  )}
                  {show ? (
                    <select
                      name=""
                      id=""
                      onChange={(e) =>
                        setUpdateDetails({
                          ...updateDetails,
                          maritalStatus: e.target.value,
                        })
                      }
                      // className="select"
                    >
                      <option value="">Enter Marital status</option>
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Seperated">Seperated</option>
                      <option value="Widow">Widow</option>
                      <option value="Widower">Widower</option>
                    </select>
                  ) : (
                    <p>{updateDetails?.maritalStatus}</p>
                  )}
                  {show ? (
                    <select
                      name=""
                      id=""
                      required
                      onChange={(e) =>
                        setUpdateDetails({
                          ...updateDetails,
                          employementStatus: e.target.value,
                        })
                      }>
                      <option value="">Employeed / UnEmployeed</option>
                      <option value="Employeed">Employeed</option>
                      <option value="Unemployeed">UnEmployeed</option>
                      <option value="Retired">Retired</option>
                      <option value="Redundant">Redundant</option>
                      <option value="Homemaker">Homemaker</option>
                      <option value="Jon">Job</option>
                    </select>
                  ) : (
                    <p>{updateDetails?.employementStatus}</p>
                  )}
                </div>
              </div>
            </div>
            <button
              onClick={() => {
                setShow(true);
              }}>
              {show ? <p onClick={update}>Update</p> : "Edit"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShowSpouseRequirements;
