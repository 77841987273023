import React, { useState } from "react";

function ReviewCard2() {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  return (
    <div className="question1">
      <div className="questions" onClick={toggle}>
        <p>How can i create my profile on this website?</p>
        <div className="remove-icon">
          <svg
            width="22"
            height="22"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="16"
              cy="16"
              r="12"
              stroke="black"
              stroke-opacity="0.8"
              stroke-width="2"
            />
            <path
              d="M10 16H22"
              stroke="black"
              stroke-opacity="0.8"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
      {show ? (
        <div className="answer">
          <p>
            Creating a profile on Shaadmani is quick and easy. Simply click on
            "Sign In" if you are new then click "Sign Up" button on the homepage
            and fill out the required fields such as your username, name, email
            address, and password. You will then be prompted to provide some
            additional information about yourself such as your age, gender,
            religion, education, and occupation. You can also add a profile
            picture and write a brief introduction about yourself to make your
            profile. Once you have completed your profile shaadmani team search
            for your matching partner and will let you know. It's that simple!
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default ReviewCard2;
