import React, { useEffect, useState } from "react";
import "./Forms.css";
import validationSchema from "../../utils/schemas/validationSchema";
import ProfileImage from "../ProfileImage/ProfileImage";
import { Formik, Form, ErrorMessage } from "formik";
import InputField from "../FormComponents/InputField";
import SelectField from "../FormComponents/SelectField";
import { personalDetails } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getUserAction } from "../../redux/user/actionCreator";
import { RotatingLines } from "react-loader-spinner";
import TextAreaField from "../FormComponents/TextAreaField";
import { getAllCountries, getCities } from "../../utils/countries";

const initialValues = {
  bio: "",
  image: "",
  dateofBirth: "",
  gender: "",
  height: {
    feet: "",
    inches: "",
  },
  weight: "",
  nationality: "",
  noOfSiblings: "",
  alcohol: "",
  smoker: "",
  profession: "",
  phoneNum: "",
  maritalStatus: "",
  location: "",
  city: "",
  relocation: "",
  nextOfKin: "",
};

function PersonalDetailsForm() {
  const [uploadImage, setUploadImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [details, setDetails] = useState(initialValues);
  const [allCountries, setAllCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [cities, setAllCities] = useState([]);
  const navigate = useNavigate();

  const cloudinaryUploadImage = async () => {
    const form = new FormData();
    form.append("file", uploadImage);
    form.append("upload_preset", "shaadmani");
    form.append("cloud_name", "dtib2nmzi");
    const { data } = await axios.post(
      "https://api.cloudinary.com/v1_1/dtib2nmzi/image/upload",
      form,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    console.log("data", data);
    return data.secure_url;
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserAction());
  }, [dispatch]);

  const user = useSelector((state) => state.user?.user);
  console.log("userrrr", user);

  const handlechange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleSelect = (fieldname, selectedOption) => {
    console.log("jadu 1", selectedOption, fieldname);
    if (fieldname == "location") {
      setCountry(selectedOption.value);
    }
    console.log(" selected country", selectedOption);
    console.log("field name", fieldname);

    setDetails({ ...details, [fieldname]: selectedOption.value });
  };

  const handleHeightChange = (e) => {
    const { name, value } = e.target;
    setDetails(() => ({
      ...details,
      height: {
        ...details.height,
        [name]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    setSubmitClicked(true);
    setLoading(true);
    const source_URL_Image = await cloudinaryUploadImage();
    console.log("source", source_URL_Image);
    personalDetails({
      ...details,
      id: user?._id,
      profileImage: source_URL_Image,
    })
      .then(({ data }) => {
        console.log("data: 76", user, "UserEnteredData", data);
        navigate("/religion-detail");
      })
      .catch((e) => console.log(e));
    setLoading(false);
  };

  useEffect(() => {
    getAllCountries(setAllCountries);
  }, []);
  console.log("countiresss", allCountries);
  useEffect(() => {
    if (country !== "") {
      getCities(country, setAllCities);
    }
  }, [country]);
  console.log("cities 143", cities);
  console.log("detailssssss", details);
  return (
    <div className="formContainer">
      <div className="mainDivFormPersonal">
        <h1>Personal Details</h1>
        <Formik
          initialValues={details}
          enableReinitialize={true}
          validationSchema={validationSchema.personalDetails}
          onSubmit={handleSubmit}>
          {({ errors, touched }) => {
            return (
              <Form>
                <div className="upperSection">
                  <div className="sec1">
                    {/* <ProfileImage
                      name="image"
                      uploadImage={uploadImage}
                      setUploadImage={setUploadImage}
                      hasError={touched.image && errors.image}
                      errorMessage={errors.image}
                    /> */}
                    <ProfileImage
                      uploadImage={uploadImage}
                      setUploadImage={setUploadImage}
                      submitClicked={submitClicked}
                    />
                    {submitClicked && !uploadImage && (
                      <div
                        style={{
                          marginLeft: "2rem",
                          fontWeight: "bold",
                          color: "red",
                        }}>
                        Please select an image
                        {setLoading(false)}
                      </div>
                    )}
                  </div>
                  <div className="sec2">
                    <TextAreaField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="textArea"
                      cols="45"
                      rows="4"
                      label="User Bio"
                      placeholder="Enter your bio"
                      type="text"
                      name="bio"
                      value={details.bio}
                      onChange={handlechange}
                      hasError={touched.bio && errors.bio}
                      errorMessage={errors.bio}
                    />
                  </div>
                </div>
                <div className="maindivforDivision">
                  <div className="formSeperation">
                    <InputField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formInputNew"
                      label="Date of Birth"
                      type="date"
                      name="dateofBirth"
                      value={details.dateofBirth}
                      onChange={handlechange}
                      hasError={touched.dateofBirth && errors.dateofBirth}
                      errorMessage={errors.dateofBirth}
                    />
                    <SelectField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formSelect"
                      label="Gender"
                      name="gender"
                      placeholder="Select Gender"
                      onChange={(selectedOption) =>
                        handleSelect("gender", selectedOption)
                      }
                      options={[
                        { value: "Male", label: "Male" },
                        { value: "Female", label: "Female" },
                      ]}
                      hasError={touched.gender && errors.gender}
                      errorMessage={errors.gender}
                    />
                    <div className="heightSelect">
                      <InputField
                        classNameLabel="formLabel"
                        classNameInput="formInputNew"
                        label="Height"
                        type="number"
                        placeholder="feet"
                        name="feet"
                        value={details.height.feet}
                        onChange={handleHeightChange}
                        // hasError={touched.feet && errors.feet}
                        // errorMessage={errors.feet}
                      />
                      <InputField
                        classNameLabel="formLabel"
                        classNameInput="formInputNew"
                        type="number"
                        name="inches"
                        placeholder="inch"
                        value={details.height.inches}
                        onChange={handleHeightChange}
                        // hasError={touched.inches && errors.inches}
                        // errorMessage={errors.inches}
                      />
                    </div>
                    <ErrorMessage
                      style={{ color: "red" }}
                      name="height.feet"
                      component="div"
                    />
                    <InputField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formInputNew"
                      label="Weight"
                      type="number"
                      name="weight"
                      placeholder="Your Weight"
                      value={details.weight}
                      onChange={handlechange}
                      hasError={touched.weight && errors.weight}
                      errorMessage={errors.weight}
                    />
                    <InputField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formInputNew"
                      label="Nationality"
                      type="text"
                      name="nationality"
                      value={details.nationality}
                      placeholder="Your Nationality"
                      onChange={handlechange}
                      hasError={touched.nationality && errors.nationality}
                      errorMessage={errors.nationality}
                    />
                    <InputField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formInputNew"
                      type="text"
                      name="noOfSiblings"
                      placeholder="No of siblings"
                      value={details.noOfSiblings}
                      onChange={handlechange}
                      label="No of siblings"
                      hasError={touched.noOfSiblings && errors.noOfSiblings}
                      errorMessage={errors.noOfSiblings}
                    />
                    <SelectField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formSelect"
                      placeholder="You live in ?"
                      label="Location (Current)"
                      name="location"
                      car="countriesss"
                      onChange={(selectedOption) =>
                        handleSelect("location", selectedOption)
                      }
                      options={allCountries?.map((country) => ({
                        value: country,
                        label: country,
                      }))}
                      hasError={touched.location && errors.location}
                      errorMessage={errors.location}
                    />
                    <SelectField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formSelect"
                      placeholder="City"
                      label="City"
                      name="city"
                      car="citiessss"
                      onChange={(selectedOption) =>
                        handleSelect("city", selectedOption)
                      }
                      options={cities?.map((city) => ({
                        value: city,
                        label: city,
                      }))}
                      hasError={touched.city && errors.city}
                      errorMessage={errors.city}
                    />
                  </div>
                  <div
                    // style={{ backgroundColor: "yellow" }}
                    className="formSeperation">
                    <SelectField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formSelect"
                      placeholder="Do you drink alcohol?"
                      label="Alcohol"
                      name="alcohol"
                      onChange={(selectedOption) =>
                        handleSelect("alcohol", selectedOption)
                      }
                      options={[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ]}
                      hasError={touched.alcohol && errors.alcohol}
                      errorMessage={errors.alcohol}
                    />
                    <SelectField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formSelect"
                      placeholder="Do you smoke ?"
                      label="Smoker"
                      name="smoker"
                      onChange={(selectedOption) =>
                        handleSelect("smoker", selectedOption)
                      }
                      options={[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ]}
                      hasError={touched.smoker && errors.smoker}
                      errorMessage={errors.smoker}
                    />
                    <InputField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formInputNew"
                      type="text"
                      name="profession"
                      placeholder="Your Profession"
                      value={details.profession}
                      onChange={handlechange}
                      label="Profession"
                      hasError={touched.profession && errors.profession}
                      errorMessage={errors.profession}
                    />
                    <InputField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formInputNew"
                      type="number"
                      name="phoneNum"
                      placeholder="Your Phone Number ?"
                      value={details.phoneNum}
                      onChange={handlechange}
                      label="Phone Number"
                      hasError={touched.phoneNum && errors.phoneNum}
                      errorMessage={errors.phoneNum}
                    />
                    <SelectField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formSelect"
                      placeholder="You are ?"
                      label="Marital Status"
                      name="maritalStatus"
                      onChange={(selectedOption) =>
                        handleSelect("maritalStatus", selectedOption)
                      }
                      options={[
                        { value: "Single", label: "Single" },
                        { value: "Married", label: "Married" },
                        { value: "Divorced", label: "Divorced" },
                        { value: "Widow", label: "Widow" },
                        { value: "Widower", label: "Widower" },
                      ]}
                      hasError={touched.maritalStatus && errors.maritalStatus}
                      errorMessage={errors.maritalStatus}
                    />

                    <SelectField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formSelect"
                      placeholder="Open to Relocation"
                      label="Open to Relocation"
                      name="relocation"
                      onChange={(selectedOption) =>
                        handleSelect("relocation", selectedOption)
                      }
                      options={[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ]}
                      hasError={touched.relocation && errors.relocation}
                      errorMessage={errors.relocation}
                    />
                    <InputField
                      classNameMain="inputMain"
                      classNameLabel="formLabel"
                      classNameInput="formInputNew"
                      label="Next of Kin (Contact Info)"
                      type="number"
                      name="nextOfKin"
                      placeholder="Next of Kin Contact"
                      value={details.nextOfKin}
                      onChange={handlechange}
                      hasError={touched.nextOfKin && errors.nextOfKin}
                      errorMessage={errors.nextOfKin}
                    />
                  </div>
                </div>
                <div className="buttonDiv">
                  <button className="formButton" type="submit">
                    {loading ? (
                      <RotatingLines
                        strokeColor="white"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="15"
                        visible={true}
                      />
                    ) : (
                      "save"
                    )}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default PersonalDetailsForm;
