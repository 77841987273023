import Cookies from "js-cookie";
import { auth_begin, auth_error, auth_success, logout } from "./action";
import * as api from "../../utils/api";

export const createAccount = (form) => {
  return async (dispatch) => {
    try {
      dispatch(auth_begin());
      console.log("ABC 1", form);
      const { data } = await api.createAccount(form);
      console.log("ABC 2", data);

      Cookies.set("shaadmani-token", data.token);
      dispatch(auth_success(data.token));
    } catch (error) {
      Cookies.remove("shaadmani-token");
      dispatch(auth_error(error));
    }
  };
};

// export const signin = (form, type) => {
//   return async (dispatch) => {
//     try {
//       dispatch(auth_begin());
//       console.log("type", type);
//       let res;
//       if (type === "Admin") {
//         const { data } = await api.adminLogin(form);
//         res = data;
//       } else if (type === "User") {
//         const { data } = await api.login(form);
//         res = data;
//       } else {
//         console.log("TYPE MUST BE SELECTED");
//       }

//       Cookies.set("shaadmani-token", res.token);
//       dispatch(auth_success(res.token));
//     } catch (error) {
//       Cookies.remove("shaadmani-token");
//       dispatch(auth_error(error));
//     }
//   };
// };

export const signin = (form) => {
  return async (dispatch) => {
    try {
      dispatch(auth_begin());
      let res;
      const { data } = await api.login(form);
      res = data;
      Cookies.set("shaadmani-token", res.token);
      dispatch(auth_success(res.token));
    } catch (error) {
      Cookies.remove("shaadmani-token");
      dispatch(auth_error(error));
    }
  };
};

export const logoutAction = () => {
  return async (dispatch) => {
    try {
      dispatch(auth_begin());
      Cookies.remove("shaadmani-token");
      dispatch(logout());
    } catch (error) {
      Cookies.remove("shaadmani-token");
      dispatch(auth_error(error));
    }
  };
};
