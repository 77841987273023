import React, { useEffect } from "react";
import PersonalDetailsForm from "../../components/Forms/PersonalDetailsForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function PersonalDetails() {
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.user?.weight) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (!auth.token) {
      navigate("/");
    }
  }, [auth, navigate]);

  return (
    <div>
      <div>
        <PersonalDetailsForm />
      </div>
    </div>
  );
}

export default PersonalDetails;
