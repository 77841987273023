import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../redux/user/actionCreator";
import { personalDetails } from "../../utils/api";
import ShowProfileImg from "../../components/ShowProfileImg/ShowProfileImg";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Loading from "../../components/Loading/Loading";

function ShowInterest() {
  const [isLoading, setIsLoading] = useState(true);

  const [show, setShow] = useState(false);
  const user = useSelector((state) => state.user.user);

  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const [updateDetails, setUpdateDetails] = useState({ user });
  useEffect(() => {
    setUpdateDetails({
      artsAndCulture: user?.artsAndCulture,
      foodAndDrink: user?.foodAndDrink,
      activism: user?.activism,
      technology: user?.technology,
      outdoor: user?.outdoor,
      sports: user?.sports,
      diet: user?.diet,
    });
    setIsLoading(false);
  }, [user, refresh]);

  const update = async (e) => {
    personalDetails({ ...updateDetails, id: user?._id })
      .catch((e) => console.log(e))
      .then(() => {
        setRefresh((r) => !r);
        setShow(false);
        dispatch(getUserAction());
        Swal.fire({
          title: "Data Updated Successfully !",
          icon: "success",
          confirmButtonColor: "#D21285",
          confirmButtonText: "OK",
          iconColor: "#D21285",
          background: "#FFE4F4",
          customClass: {
            container: "my-swal-border",
          },
        });
      });
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div className="show-profile">
            <h2>Interest</h2>
            <ShowProfileImg />
          </div>
          <div className="religion-details">
            <h3>Interest</h3>
            <div className="details1">
              <div style={{ display: "flex" }}>
                <div className="sub-details">
                  <p>Arts & culture</p>
                  <p>Food & Drink</p>
                  <p>Activism</p>
                  <p>Diet</p>
                </div>
                <div className="sub-details3">
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails?.artsAndCulture}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          artsAndCulture: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.artsAndCulture}</p>
                  )}
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails?.foodAndDrink}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          foodAndDrink: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.foodAndDrink}</p>
                  )}
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails?.activism}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          activism: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.activism}</p>
                  )}
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails?.diet}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          diet: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.diet}</p>
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="sub-details">
                  <p>Technology</p>
                  <p>Outdoors</p>
                  <p>Sports</p>
                </div>
                <div className="sub-details3">
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails?.technology}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          technology: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.technology}</p>
                  )}
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails?.outdoor}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          outdoor: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.outdoor}</p>
                  )}
                  {show ? (
                    <input
                      type="text"
                      value={updateDetails?.sports}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          sports: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.sports}</p>
                  )}
                </div>
              </div>
            </div>
            <button onClick={() => setShow(true)}>
              {show ? <p onClick={update}>Update</p> : "Edit"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShowInterest;
