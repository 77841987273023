import React from "react";
import { useSelector } from "react-redux";

function ProfileImage({
  uploadImage,
  setUploadImage,
  hasError,
  errorMessage,
  name,
  value,
}) {
  const user = useSelector((state) => state.user.user);

  return (
    <div style={{ width: "100%" }} className="user-img">
      <div className="name">
        <div className="img">
          <img
            src={
              uploadImage
                ? window.webkitURL.createObjectURL(uploadImage)
                : user?.profileImage
            }
            alt=""
            height="100%"
            width="100%"
            style={{ borderRadius: "94px" }}
          />
          <label htmlFor="user-image" className="camera-icon">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="15"
                cy="15"
                r="15"
                fill="url(#paint0_linear_102_484)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.08 10.6773C19.11 10.7297 19.1625 10.7672 19.23 10.7672C21.03 10.7672 22.5 12.2356 22.5 14.0336V18.4836C22.5 20.2816 21.03 21.75 19.23 21.75H10.77C8.9625 21.75 7.5 20.2816 7.5 18.4836V14.0336C7.5 12.2356 8.9625 10.7672 10.77 10.7672C10.83 10.7672 10.89 10.7372 10.9125 10.6773L10.9575 10.5874C10.9834 10.533 11.0099 10.4771 11.0368 10.4203C11.2287 10.0162 11.441 9.5691 11.5725 9.30633C11.9175 8.63208 12.5025 8.25749 13.23 8.25H16.7625C17.49 8.25749 18.0825 8.63208 18.4275 9.30633C18.5456 9.5423 18.7256 9.92241 18.899 10.2888C18.9348 10.3644 18.9704 10.4394 19.005 10.5125L19.08 10.6773ZM18.5325 13.5541C18.5325 13.9287 18.8325 14.2284 19.2075 14.2284C19.5825 14.2284 19.89 13.9287 19.89 13.5541C19.89 13.1795 19.5825 12.8724 19.2075 12.8724C18.8325 12.8724 18.5325 13.1795 18.5325 13.5541ZM13.7025 14.7153C14.055 14.3632 14.5125 14.1759 15 14.1759C15.4875 14.1759 15.945 14.3632 16.29 14.7078C16.635 15.0524 16.8225 15.5094 16.8225 15.9964C16.815 17.0003 16.005 17.8169 15 17.8169C14.5125 17.8169 14.055 17.6296 13.71 17.285C13.365 16.9403 13.1775 16.4834 13.1775 15.9964V15.9889C13.17 15.5169 13.3575 15.0599 13.7025 14.7153ZM17.0775 18.0791C16.545 18.611 15.81 18.9406 15 18.9406C14.2125 18.9406 13.4775 18.6335 12.915 18.0791C12.36 17.5172 12.0525 16.783 12.0525 15.9964C12.045 15.2173 12.3525 14.4831 12.9075 13.9212C13.47 13.3593 14.2125 13.0522 15 13.0522C15.7875 13.0522 16.53 13.3593 17.085 13.9137C17.64 14.4756 17.9475 15.2173 17.9475 15.9964C17.94 16.813 17.61 17.5472 17.0775 18.0791Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_102_484"
                  x1="15"
                  y1="0"
                  x2="15"
                  y2="30"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#D21285" />
                  <stop offset="1" stop-color="#30D5C8" />
                </linearGradient>
              </defs>
            </svg>
          </label>

          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="user-image"
            name={name}
            value={value}
            onChange={(e) => {
              setUploadImage(e.target.files[0]);
            }}
          />
        </div>

        <p>{user?.userName}</p>
      </div>
      {hasError && (
        <div>
          <p style={{ color: "red", margin: "3px 0 3px 0" }}>{errorMessage}</p>
        </div>
      )}
    </div>
  );
}

export default ProfileImage;
