import React from "react";
import ReligiousDetailsForm from "../../components/Forms/ReligiousDetailsForm";

function ReligiousDetails() {
  return (
    <div>
      <ReligiousDetailsForm />
    </div>
  );
}

export default ReligiousDetails;
