import React, { useEffect, useState } from "react";
import validationSchema from "../../utils/schemas/validationSchema";
import { Formik, Form, ErrorMessage } from "formik";
import { RotatingLines } from "react-loader-spinner";
import InputField from "../FormComponents/InputField";
import SelectField from "../FormComponents/SelectField";
import cities from "../FormComponents/cities.json";
import { useNavigate } from "react-router-dom";
import { addspouseDetails } from "../../utils/api";
import "./Forms.css";
import { getAllCountries, getCities } from "../../utils/countries";

const initialValues = {
  age: {
    ageFrom: "",
    ageTo: "",
  },
  height: {
    feetFrom: "",
    inchesFrom: "",
    feetTo: "",
    inchesTo: "",
  },
  qualification: "",
  location: "",
  city: "",
  maritalStatus: "",
  employementStatus: "",
};

function SpouseRequirementsForm() {
  const [details, setDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [cities, setAllCities] = useState([]);
  const [country, setCountry] = useState([]);

  const handlechange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    console.log(details);
  };
  const handleSelect = (fieldname, selectedOption) => {
    if (fieldname == "location") {
      setCountry(selectedOption.value);
    }

    setDetails({ ...details, [fieldname]: selectedOption.value });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    const [category, fieldName] = name.split(".");
    setDetails((prevDetails) => ({
      ...prevDetails,
      [category]: {
        ...prevDetails[category],
        [fieldName]: value,
      },
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    console.log("first", details);
    const response = addspouseDetails({ ...details })
      .then(({ data }) => {
        console.log("data:", data);
        navigate("/dashboard");
        console.log("2222", data);
      })
      .catch((e) => console.log(e));
    setLoading(false);
  };

  useEffect(() => {
    getAllCountries(setAllCountries);
  }, []);
  console.log("countiresss", allCountries);

  useEffect(() => {
    if (country !== "") {
      getCities(country, setAllCities);
    }
  }, [country]);
  console.log("countries", allCountries);
  console.log("cities ", cities);
  console.log("detailsssssss 89 ", details);
  return (
    <div className="formContainer">
      <div className="mainDivFormm">
        <h1>Spouse Requirements form</h1>
        <Formik
          initialValues={details}
          enableReinitialize={true}
          validationSchema={validationSchema.spouseRequirements}
          onSubmit={handleSubmit}>
          {({ errors, touched }) => (
            <Form>
              <div className="maindivforDivision">
                <div className="formSeperation">
                  <div className="heightSelect">
                    <InputField
                      classNameLabel="formLabel"
                      classNameInput="formInput"
                      label="Age"
                      type="number"
                      placeholder="Age (from)"
                      name="age.ageFrom"
                      value={details.age?.ageFrom}
                      onChange={handleChange2}
                      hasError={touched?.age?.ageFrom && errors?.age?.ageFrom}
                      errorMessage={errors?.age?.ageFrom}
                    />

                    <InputField
                      noLabel="no"
                      classNameLabel="formLabel"
                      classNameWithoutLabel="formNoLabel"
                      classNameInput="formInput"
                      type="number"
                      placeholder="Age (To)"
                      name="age.ageTo"
                      value={details.age.ageTo}
                      onChange={handleChange2}
                      hasError={touched?.age?.ageTo && errors?.age?.ageTo}
                      errorMessage={errors?.age?.ageTo}
                    />
                  </div>

                  <div className="heightSelect">
                    <InputField
                      classNameLabel="formLabel"
                      classNameInput="formInput"
                      label="Height (From)"
                      type="number"
                      placeholder="feet"
                      name="height.feetFrom"
                      value={details.height.feetFrom}
                      onChange={handleChange2}
                      hasError={
                        touched?.height?.feetFrom && errors?.height?.feetFrom
                      }
                      errorMessage={errors?.height?.feetFrom}
                    />
                    <InputField
                      noLabel="no"
                      classNameLabel="formLabel"
                      classNameWithoutLabel="formNoLabel"
                      classNameInput="formInput"
                      type="number"
                      name="height.inchesFrom"
                      placeholder="inch"
                      value={details.height.inchesFrom}
                      onChange={handleChange2}
                      hasError={
                        touched?.height?.inchesFrom &&
                        errors?.height?.inchesFrom
                      }
                      errorMessage={errors?.height?.inchesFrom}
                    />
                  </div>

                  <div className="heightSelect">
                    <InputField
                      classNameLabel="formLabel"
                      classNameInput="formInput"
                      label="Height (To)"
                      type="number"
                      placeholder="feet"
                      name="height.feetTo"
                      value={details.height.feetTo}
                      onChange={handleChange2}
                      hasError={
                        touched?.height?.feetTo && errors?.height?.feetTo
                      }
                      errorMessage={errors?.height?.feetTo}
                    />
                    <InputField
                      noLabel="no"
                      classNameLabel="formLabel"
                      classNameWithoutLabel="formNoLabel"
                      classNameInput="formInput"
                      type="number"
                      name="height.inchesTo"
                      placeholder="inch"
                      value={details.height.inchesTo}
                      onChange={handleChange2}
                      hasError={
                        touched?.height?.inchesTo && errors?.height?.inchesTo
                      }
                      errorMessage={errors?.height?.inchesTo}
                    />
                  </div>

                  <SelectField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formSelect"
                    label="Qualification"
                    name="qualification"
                    placeholder="Spouse qualification ?"
                    onChange={(selectedOption) =>
                      handleSelect("qualification", selectedOption)
                    }
                    options={[
                      { value: "Matric", label: "Matric" },
                      { value: "Intermediate", label: "Intermediate" },
                      {
                        value: "Bachlors of Science BS",
                        label: "Bachlors of Science BS",
                      },
                      {
                        value: "Master of Science MS",
                        label: "Master of Science MS",
                      },
                      {
                        value: "Doctor of Philosophy (PhD)",
                        label: "Doctor of Philosophy (PhD)",
                      },
                    ]}
                    hasError={touched.qualification && errors.qualification}
                    errorMessage={errors.qualification}
                  />
                </div>
                <div className="formSeperation">
                  <SelectField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formSelect"
                    placeholder="Select location"
                    label="Location (Current)"
                    name="location"
                    onChange={(selectedOption) =>
                      handleSelect("location", selectedOption)
                    }
                    options={allCountries.map((country) => ({
                      value: country,
                      label: country,
                    }))}
                    hasError={touched.location && errors.location}
                    errorMessage={errors.location}
                  />
                  <SelectField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formSelect"
                    placeholder="Select city"
                    label="City"
                    name="city"
                    onChange={(selectedOption) =>
                      handleSelect("city", selectedOption)
                    }
                    options={cities.map((city) => ({
                      value: city,
                      label: city,
                    }))}
                    hasError={touched.city && errors.city}
                    errorMessage={errors.city}
                  />
                  <SelectField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formSelect"
                    placeholder="Spouse marital status ?"
                    label="Marital Status"
                    name="maritalStatus"
                    onChange={(selectedOption) =>
                      handleSelect("maritalStatus", selectedOption)
                    }
                    options={[
                      { value: "Single", label: "Single" },
                      { value: "Married", label: "Married" },
                      { value: "Divorced", label: "Divorced" },
                      { value: "Widow", label: "Widow" },
                      { value: "Widower", label: "Widower" },
                    ]}
                    hasError={touched.maritalStatus && errors.maritalStatus}
                    errorMessage={errors.maritalStatus}
                  />
                  <SelectField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formSelect"
                    label="Employment"
                    name="employementStatus"
                    placeholder="Spouse education ?"
                    onChange={(selectedOption) =>
                      handleSelect("employementStatus", selectedOption)
                    }
                    options={[
                      { value: "Employeed", label: "Employeed" },
                      { value: "UnEmployeed", label: "UnEmployeed" },
                      { value: "Retired", label: "Retired" },
                      { value: "Redundant", label: "Redundant" },
                      { value: "Homemaker", label: "Homemaker" },
                    ]}
                    hasError={
                      touched.employementStatus && errors.employementStatus
                    }
                    errorMessage={errors.employementStatus}
                  />
                </div>
              </div>
              <div className="buttonDiv">
                <button className="formButton" type="submit">
                  {loading ? (
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={true}
                    />
                  ) : (
                    "save"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default SpouseRequirementsForm;
