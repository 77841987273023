import React from "react";
import "./CnicComponent.css"

function CnicComponent({id, setImage, image, setChanged, text}) {
  return (
    <div className="cnic-card" style={{flexDirection:"column"}}>
      <h3 style={{padding:"10px", marginTop:"3rem"}}>{text}</h3>
      {image ? (
        <>
          <img
            src={
              typeof image === "string"
                ? image
                : window.webkitURL.createObjectURL(image)
            }
            alt="profile picture"
            height="100%"
            width="100%"
            style={{ borderRadius: "10px" }}
          />
          <label
            htmlFor={id}
            // style={{
            //   background: "transparent",
            //   width: 150,
            //   height: 200,
              // position: "absolute",
            // }}
            className="label"
          />
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id={id}
            onChange={(e) => {
              setImage(e.target.files[0]);
              setChanged(true);
            }}
          />
        </>
      ) : (
        <>
          <label htmlFor={id} className="plus-icon">
            +
          </label>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id={id}
            onChange={(e) => {
              setImage(e.target.files[0]);
              setChanged(true);
            }}
          />
        </>
      )}
    </div>
  );
}

export default CnicComponent;
