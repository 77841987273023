import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Image from "../../components/Image/Image";
import UserProfile from "../../components/User-Profile/UserProfile";
import { personalDetails } from "../../utils/api";
import "./multimedia.css";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import Loading from "../../components/Loading/Loading";
import CnicComponent from "../../components/CnicComponent/CnicComponent";

function Multimedia() {
  const [isLoading, setIsLoading] = useState(true);

  const [image1, setImage1] = useState();
  const [image1Changed, setImage1Changed] = useState(false);

  const [image2, setImage2] = useState();
  const [image2Changed, setImage2Changed] = useState(false);

  const [image3, setImage3] = useState();
  const [image3Changed, setImage3Changed] = useState(false);

  const [image4, setImage4] = useState();
  const [image4Changed, setImage4Changed] = useState(false);

  const [image5, setImage5] = useState();
  const [image5Changed, setImage5Changed] = useState(false);

  const [image6, setImage6] = useState();
  const [image6Changed, setImage6Changed] = useState(false);

  const [cnicImageFront, setCnicImageFront] = useState();
  const [cnicImageFrontChanged, setCnicImageFrontChanged] = useState(false);

  const [cnicImageBack, setCnicImageBack] = useState();
  const [cnicImageBackChanged, setCnicImageBackChanged] = useState(false);

  const [profileImage, setProfileImage] = useState("");
  const [profileImageChanged, setProfileImageChanged] = useState(false);

  const [submitClicked, setSubmitClicked] = useState(false);

  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user.user);

  const cloudinaryUploadImage = async (recSource) => {
    const form = new FormData();
    form.append("file", recSource);

    form.append("upload_preset", "shaadmani");
    form.append("cloud_name", "dtib2nmzi");
    const { data } = await axios.post(
      "https://api.cloudinary.com/v1_1/dtib2nmzi/image/upload",
      form,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    console.log("data", data.secure_url);
    return data.secure_url;
  };

  const handleSubmit = async (e) => {
    setSubmitClicked(true);
    setLoading(true);
    setIsLoading(false);

    e.preventDefault();
    let images = [null, null, null, null, null, null];
    let CNICimages = [null, null];
    let profile;

    if (image1Changed) {
      const source_URL_Image = await cloudinaryUploadImage(image1);
      images[0] = source_URL_Image;
      setImage1Changed(false);
    } else {
      images[0] = image1;
    }

    if (image2Changed) {
      const source_URL_Image = await cloudinaryUploadImage(image2);
      images[1] = source_URL_Image;
      setImage2Changed(false);
    } else {
      images[1] = image2;
    }

    if (image3Changed) {
      const source_URL_Image = await cloudinaryUploadImage(image3);
      images[2] = source_URL_Image;
      setImage3Changed(false);
    } else {
      images[2] = image3;
    }

    if (image4Changed) {
      const source_URL_Image = await cloudinaryUploadImage(image4);
      images[3] = source_URL_Image;
      setImage4Changed(false);
    } else {
      images[3] = image4;
    }

    if (image5Changed) {
      const source_URL_Image = await cloudinaryUploadImage(image5);
      images[4] = source_URL_Image;
      setImage5Changed(false);
    } else {
      images[4] = image5;
    }

    if (image6Changed) {
      const source_URL_Image = await cloudinaryUploadImage(image6);
      images[5] = source_URL_Image;
      setImage6Changed(false);
    } else {
      images[5] = image6;
    }

    if (cnicImageFrontChanged) {
      console.log("cnicImageFront", cnicImageFront);
      const source_URL_Image = await cloudinaryUploadImage(cnicImageFront);
      CNICimages[0] = source_URL_Image;
      setCnicImageFrontChanged(false);
    } else {
      CNICimages[0] = cnicImageFront;
    }
    if (cnicImageBackChanged) {
      const source_URL_Image = await cloudinaryUploadImage(cnicImageBack);
      CNICimages[1] = source_URL_Image;
      setCnicImageBackChanged(false);
    } else {
      CNICimages[1] = cnicImageBack;
    }

    if (profileImageChanged) {
      const source_URL_Image = await cloudinaryUploadImage(profileImage);
      profile = source_URL_Image;
      setProfileImageChanged(false);
    } else {
      profile = user?.profileImage;
    }

    personalDetails({
      images,
      CNICimages,
      id: user?._id,
      profileImage: profile,
    })
      .then(({ data }) => {
        console.log(data);
        setImage1(data.images[0]);
        setImage2(data.images[1]);
        setImage3(data.images[2]);
        setImage4(data.images[3]);
        setImage5(data.images[4]);
        setImage6(data.images[5]);

        setCnicImageFront(data.CNICimages[0]);
        setCnicImageBack(data.CNICimages[1]);
      })

      .catch((e) => console.log(e));
    setLoading(false);
  };

  useEffect(() => {
    if (user?.images) {
      setImage1(user?.images[0]);
      setImage2(user?.images[1]);
      setImage3(user?.images[2]);
      setImage4(user?.images[3]);
      setImage5(user?.images[4]);
      setImage6(user?.images[5]);

      setIsLoading(false);
    }
    if (user?.CNICimages) {
      setCnicImageFront(user?.CNICimages[0]);
      setCnicImageBack(user?.CNICimages[1]);
      setIsLoading(false);
    }
  }, [user]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <form action="" onSubmit={handleSubmit}>
            <div className="">
              <UserProfile
                image={profileImage}
                setImage={setProfileImage}
                setChanged={setProfileImageChanged}
              />
            </div>
            <div className="user-pics">
              <h3>Picture </h3>
              <div className="all-imgs">
                <Image
                  id="image1"
                  setImage={setImage1}
                  image={image1}
                  setChanged={setImage1Changed}
                />

                <Image
                  id="image2"
                  setImage={setImage2}
                  image={image2}
                  setChanged={setImage2Changed}
                />

                <Image
                  id="image3"
                  setImage={setImage3}
                  image={image3}
                  setChanged={setImage3Changed}
                />

                <Image
                  id="image4"
                  setImage={setImage4}
                  image={image4}
                  setChanged={setImage4Changed}
                />

                <Image
                  id="image5"
                  setImage={setImage5}
                  image={image5}
                  setChanged={setImage5Changed}
                />

                <Image
                  id="image6"
                  setImage={setImage6}
                  image={image6}
                  setChanged={setImage6Changed}
                />
              </div>
            </div>
            {/* <p>comment</p> */}

            <div className="user-pics">
              <h3>CNIC Picture </h3>
              {/* <div className="all-imgs">
                <Image
                  id="cnicImageFront"
                  setImage={setCnicImageFront}
                  image={cnicImageFront}
                  setChanged={setCnicImageFrontChanged}
                />

                <Image
                  id="cnicImageBack"
                  setImage={setCnicImageBack}
                  image={cnicImageBack}
                  setChanged={setCnicImageBackChanged}
                />
              </div> */}
            </div>
            <div className="cnic-cards" >
              <CnicComponent
                text="Front Picture"
                id="cnicImageFront"
                setImage={setCnicImageFront}
                image={cnicImageFront}
                setChanged={setCnicImageFrontChanged}
              />
               <CnicComponent
               text="Back Picture"
                id="cnicImageBack"
                setImage={setCnicImageBack}
                image={cnicImageBack}
                setChanged={setCnicImageBackChanged}
              />
            </div>

            {/* <p>comment</p> */}
            {/* <div className="cnic">
              <label htmlFor="cnic-img">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 7V2H10C7.17157 2 5.75736 2 4.87868 2.87868C4 3.75736 4 5.17157 4 8V16C4 18.8284 4 20.2426 4.87868 21.1213C5.75736 22 7.17157 22 10 22H14C16.8284 22 18.2426 22 19.1213 21.1213C20 20.2426 20 18.8284 20 16V10H15L14.9456 10H14.9456H14.9456C14.5215 10.0001 14.1094 10.0002 13.7695 9.95447C13.3863 9.90295 12.949 9.77743 12.5858 9.41421C12.2226 9.051 12.097 8.61372 12.0455 8.23052C11.9998 7.8906 11.9999 7.47848 12 7.05441L12 7ZM14 7V2.00462C14.4116 2.01481 14.684 2.04749 14.9369 2.15224C15.3045 2.30448 15.5935 2.59351 16.1716 3.17157L18.8284 5.82843C19.4065 6.40649 19.6955 6.69552 19.8478 7.06306C19.9525 7.31595 19.9852 7.58836 19.9954 8H15C14.5003 8 14.2262 7.99788 14.036 7.97231L14.0287 7.97131L14.0277 7.96402C14.0021 7.77383 14 7.49967 14 7ZM10.8377 12.1239L14.3341 13.8421C15.2219 14.2784 15.222 15.7216 14.3341 16.1579L10.8377 17.8761C9.96773 18.3037 9 17.5719 9 16.4867V13.5133C9 12.4281 9.96773 11.6963 10.8377 12.1239Z"
                    fill="#9C9C9C"
                  />
                </svg>
              </label>
              <input
                type="file"
                accept="image/*"
                multiple="true"
                id="cnic-img"
                // required
                style={{ display: "none" }}
                onChange={(e) => {
                  setcnicImg(e.target.files[0]);
                }}
              />

              <p>Attach your cnic</p>
              
            </div> */}

            <button className="save" type="submit">
              {loading ? (
                <RotatingLines
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              ) : (
                "Save"
              )}
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default Multimedia;
