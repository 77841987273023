import * as Yup from "yup";
import { phoneNumberRegex } from "./validator";
const personalDetailsSchema = Yup.object().shape({
  bio: Yup.string().required("Bio is required"),
  dateofBirth: Yup.date().required("Date of birth is required"),
  height: Yup.object().shape({
    feet: Yup.number()
      .positive("Height must be a positive number")
      .typeError("Height must be a number"),
    inches: Yup.number()
      .typeError("Inches must be a number")
      .positive("Height must be a positive number"),
  }),
  gender: Yup.string().test(
    "isSelected",
    "Please select your gender",
    (value) => {
      return value && value.value !== "";
    }
  ),
  weight: Yup.number()
    .required("Weight is required")
    .typeError("Weight must be a number")
    .positive("Weight must be a positive number"),
  nationality: Yup.string().required("Nationality is required"),
  noOfSiblings: Yup.number()
    .required("Number of siblings is required")
    .typeError("Must be a number")
    .positive("Must be a positive number"),
  alcohol: Yup.boolean().required("Alcohol field is required"),
  smoker: Yup.boolean().required("Smoker field is required"),
  profession: Yup.string().required("Profession is required"),
  phoneNum: Yup.string()
    .required("Phone number is required")
    .matches(phoneNumberRegex, "Invalid phone number"),
  maritalStatus: Yup.string().required("Marital status is required"),
  location: Yup.string().test(
    "isSelected",
    "Please select your location",
    (value) => {
      return value && value.value !== "";
    }
  ),
  city: Yup.string().test("isSelected", "Please select your city", (value) => {
    return value && value.value !== "";
  }),
  relocation: Yup.boolean().required("Relocation field is required"),
  nextOfKin: Yup.string()
    .required("Contact is required")
    .matches(phoneNumberRegex, "Invalid phone number"),
});
const religiousDetailsSchema = Yup.object().shape({
  religion: Yup.string().test(
    "isSelected",
    "Please select your religion",
    (value) => {
      return value && value.value !== "";
    }
  ),
  convertReligion: Yup.string().test(
    "isSelected",
    "Please select your option",
    (value) => {
      return value && value.value !== "";
    }
  ),
  practicing: Yup.string().test(
    "isSelected",
    "Please select your option",
    (value) => {
      return value && value.value !== "";
    }
  ),
  praying: Yup.string().test(
    "isSelected",
    "Please select your option",
    (value) => {
      return value && value.value !== "";
    }
  ),
});

const interestDetailsSchema = Yup.object().shape({
  artsAndCulture: Yup.string().required("Field is required"),
  foodAndDrink: Yup.string().required("Field is required"),
  activism: Yup.string().required("Field is required"),
  diet: Yup.string().required("Field is required"),
  technology: Yup.string().required("Field is required"),
  outdoor: Yup.string().required("Field is required"),
  sports: Yup.string().required("Field is required"),
});

const qualificationDetailsSchema = Yup.object().shape({
  educationLevel: Yup.string().test(
    "isSelected",
    "Please select your education level",
    (value) => {
      return value && value.value !== "";
    }
  ),
  language: Yup.string().test(
    "isSelected",
    "Please select your language",
    (value) => {
      return value && value.value !== "";
    }
  ),
  jobTitle: Yup.string().required("Job title is required"),
  employementStatus: Yup.string().test(
    "isSelected",
    "Please select your employement status",
    (value) => {
      return value && value.value !== "";
    }
  ),
});
const spouseRequirementsSchema = Yup.object().shape({
  age: Yup.object().shape({
    ageFrom: Yup.number()
      .positive("Age from must be a positive number")
      .typeError("Age must be a number")
      .required("Age from is required"),
    ageTo: Yup.number()
      .positive("Age to must be a positive number")
      .typeError("Age to must be a number")
      .required("Age to is required"),
  }),
  height: Yup.object().shape({
    feetFrom: Yup.number()
      .positive("Height (feet) must be a positive number")
      .typeError("Height (feet) must be a number")
      .required("Height (feet) from is required"),
    feetTo: Yup.number()
      .positive("Height (feet) must be a positive number")
      .typeError("Height (feet) must be a number")
      .required("Height (feet) is required"),
    inchesFrom: Yup.number()
      .positive("Height (inches) must be a positive number")
      .typeError("Height (inches) must be a number")
      .required("Height (inches) is required"),
    inchesTo: Yup.number()
      .positive("Height (inches) must be a positive number")
      .typeError("Height (inches) must be a number")
      .required("Height (inches) is required"),
  }),

  qualification: Yup.string().test(
    "isSelected",
    "Please select spouse qualification",
    (value) => {
      return value && value.value !== "";
    }
  ),
  location: Yup.string().test(
    "isSelected",
    "Please select desired location",
    (value) => {
      return value && value.value !== "";
    }
  ),
  city: Yup.string().test("isSelected", "Please select city", (value) => {
    return value && value.value !== "";
  }),
  maritalStatus: Yup.string().test(
    "isSelected",
    "Please select marital status",
    (value) => {
      return value && value.value !== "";
    }
  ),
  employementStatus: Yup.string().test(
    "isSelected",
    "Please select employement status",
    (value) => {
      return value && value.value !== "";
    }
  ),
});

const signUpSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  userName: Yup.string().required("Username is required"),
  fullName: Yup.string().required("Full name is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const signInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const facebookSchema = Yup.object().shape({
  facebookProfileLink: Yup.string()
    .matches(
      /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.]+(\/)?$/,
      "Please enter a valid Facebook profile link"
    )
    .required("Facebook profile link is required"),
});
const whatsappSchema = Yup.object().shape({
  whatsappProfileLink: Yup.string()
    .required("Phone number is required")
    .matches(phoneNumberRegex, "Invalid phone number"),
});
export default {
  personalDetails: personalDetailsSchema,
  religiousDetails: religiousDetailsSchema,
  interestDetails: interestDetailsSchema,
  qualificationDetails: qualificationDetailsSchema,
  qualificationDetails: qualificationDetailsSchema,
  spouseRequirements: spouseRequirementsSchema,
  signUp: signUpSchema,
  signIn: signInSchema,
  facebook: facebookSchema,
  whatsapp: whatsappSchema,
};
