import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { personalDetails } from "../../utils/api";
import "./ShowReligiousDetails.css";
import { getUserAction } from "../../redux/user/actionCreator";
import ShowProfileImg from "../../components/ShowProfileImg/ShowProfileImg";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Loading from "../../components/Loading/Loading";

function ShowReligiousDetails() {
  const [isLoading, setIsLoading] = useState(true);

  const [show, setShow] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const [updateDetails, setUpdateDetails] = useState({ user });

  useEffect(() => {
    setUpdateDetails({
      religion: user?.religion,
      convertReligion: user?.convertReligion,
      practicing: user?.practicing,
      praying: user?.praying,
      // diet: user?.diet,
      alcohol: user?.alcohol,
      smoker: user?.smoker,
    });
    setIsLoading(false);
  }, [user, refresh]);

  const update = async (e) => {
    personalDetails({ ...updateDetails, id: user?._id })
      .catch((e) => console.log(e))
      .then(() => {
        setRefresh((r) => !r);
        setShow(false);
        Swal.fire({
          title: "Data Updated Successfully !",
          icon: "success",
          confirmButtonColor: "#D21285",
          confirmButtonText: "OK",
          iconColor: "#D21285",
          background: "#FFE4F4",
          customClass: {
            container: "my-swal-border",
          },
        });
        dispatch(getUserAction());
      });
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div className="show-profile">
            <h2>Profile</h2>
            <ShowProfileImg />
          </div>
          <div className="religion-details">
            <h3>Religious Details</h3>
            <div className="details1">
              <div className="test-detail">
                <div className="sub-details">
                  <p>Religion</p>
                  <p>Convert / Revert</p>
                  <p>Practicing</p>
                  <p>Praying</p>
                </div>
                <div className="sub-details3">
                  {show ? (
                    <select
                      name=""
                      id=""
                      required
                      onChange={(e) =>
                        setUpdateDetails({
                          ...updateDetails,
                          religion: e.target.value,
                        })
                      }
                    >
                      <option value="">Religion</option>
                      <option value="Spirtual">Spirtual</option>
                      <option value="Muslim">Muslim</option>
                      <option value="Christian">Christian</option>
                      <option value="Jewism">Jewism</option>
                      <option value="Sikhism">Sikhism</option>
                      <option value="Janism">Janism</option>
                      <option value="Others">Others</option>
                      <option value="Prefer not to say">
                        Prefer not to say
                      </option>
                    </select>
                  ) : (
                    <p>{user?.religion}</p>
                  )}
                  {show ? (
                    <div
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        marginTop: "6px",
                      }}
                    >
                      <input
                        type="radio"
                        name="checkReligion"
                        value="true"
                        onChange={(e) => {
                          setUpdateDetails({
                            ...updateDetails,
                            convertReligion: e.target.value,
                          });
                        }}
                      />
                      <label for="html">Yes</label>
                      <input
                        type="radio"
                        name="checkReligion"
                        value="false"
                        onChange={(e) => {
                          setUpdateDetails({
                            ...updateDetails,
                            convertReligion: e.target.value,
                          });
                        }}
                      />
                      <label for="css">No</label>
                    </div>
                  ) : (
                    <p>{user?.convertReligion === true ? "Yes" : "No"}</p>
                  )}
                  {show ? (
                    <div
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        marginTop: "1rem",
                      }}
                    >
                      <input
                        type="radio"
                        name="checkpracticing"
                        value="true"
                        onChange={(e) => {
                          setUpdateDetails({
                            ...updateDetails,
                            practicing: e.target.value,
                          });
                        }}
                      />
                      <label for="html">Yes</label>
                      <input
                        type="radio"
                        name="checkpracticing"
                        value="false"
                        onChange={(e) => {
                          setUpdateDetails({
                            ...updateDetails,
                            practicing: e.target.value,
                          });
                        }}
                      />
                      <label for="css">No</label>
                    </div>
                  ) : (
                    <p>{user?.practicing === true ? "Yes" : "No"}</p>
                  )}
                  {show ? (
                    <div
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        marginTop: "1rem",
                      }}
                    >
                      <select
                        name=""
                        id=""
                        required
                        onChange={(e) =>
                          setUpdateDetails({
                            ...updateDetails,
                            praying: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Praying Option</option>
                        <option value="Always">Always</option>
                        <option value="Often">often</option>
                        <option value="Usually">Usually</option>
                        <option value="Never Prays">Never Prays</option>
                      </select>
                    </div>
                  ) : (
                    <p>{user?.praying}</p>
                  )}
                </div>
              </div>
              <div className="test-detail">
                <div className="sub-details">
                  {/* <p>diet</p> */}
                  {/* <p>alcohol</p> */}
                  {/* <p>Smoker</p> */}
                </div>
                <div className="sub-details3">
                  {/* {show ? (
                    <input
                      type="text"
                      value={updateDetails?.diet}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          diet: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.diet}</p>
                  )} */}
                </div>
              </div>
            </div>
            <button onClick={() => setShow(true)}>
              {show ? <p onClick={update}>Update</p> : "Edit"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShowReligiousDetails;
