import React from "react";
import Select from "react-select";

const SelectField = ({
  classNameInput,
  classNameLabel,
  classNameMain,
  type,
  name,
  label,
  placeholder,
  value,
  onChange,
  options,
  hasError,
  errorMessage,
  car,
  ...rest
}) => {
  return (
    <div className={classNameMain}>
      <label className={classNameLabel} htmlFor={name}>
        {label}:
      </label>
      <Select
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: state.isFocused ? "20px" : "20px  ",
          }),
        }}
        className={`input ${classNameInput}`}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        options={options}
        {...rest}
      />
      {hasError && (
        <div>
          <p style={{ color: "red", margin: "3px 0 3px 0" }}>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default SelectField;
