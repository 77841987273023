import React from "react";
import "./landingPage.css";
import { Link } from "react-router-dom";

function Features() {
  return (
    <div>
      <div className="landingMain-container">
        <div className="landing-ImgDiv">
          <img src="../flower.png" alt="" className="landing-Image1 " />
        </div>
        <div className="landing-containerParent">
          <div className="landing-containerChild1">
            <div className="landing-ImgDiv2">
              <img src="../logo.png" alt="" className="landing-Img1" />
              <h1 className="landing-heading1">Made in heaven</h1>
              <h1 className="landing-heading2">Found at Shaadmani</h1>
            </div>
          </div>
          <div className="landing-containerChild2">
            <img src="../couple1.png" alt="" className="landing-Img2" />
          </div>
        </div>
      </div>

      {/* <div className="landing-homeBar">
        <div>
          <div className="landing-homebarmain">
            I’m looking for
            <input type="text" className="landing-homeBarSpan1" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}>
          <h1 className="landing-homeBarSpan2Text">Age</h1>
          <input type="text" className="landing-homeBarSpan2" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}>
          <h1 className="landing-homeBarSpan2Text"></h1>
          <span className="landing-homeBarSpanNew "></span>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="landing-homeBarSpan2Text">Religion</h1>
          <input type="text" className="landing-homeBarSpan4" />
        </div>{" "}
        <div className="landing-homebarmain">
          <h1 className="landing-homeBarSpan2Text">Living in</h1>
          <input className="landing-homeBarSpan4" type="text" />
        </div>
        <div className="landing-homebarmain">
          <h1 className="landing-homeBarSpan2Text"></h1>
          <Link to="/signin" style={{ textDecoration: "none" }}>
            <span className="landing-homeBarSpan3">Find out</span>
          </Link>
        </div>
      </div> */}
      <div className="new-fields">
        <div className="field1">
          <p
            style={{
              padding: "5px 0px",
            }}>
            I’m looking for
          </p>
          <input type="text" />
        </div>
        <div className="field2">
          <p
            style={{
              padding: "5px 0px",
            }}>
            Age
          </p>
          <input type="text" />
        </div>
        <div className="field2">
          <p
            style={{
              padding: "17px 0px",
            }}></p>
          <input style={{ marginTop: " 22px;" }} type="text" />
        </div>
        <div className="field1">
          <p
            style={{
              padding: "5px 0px",
            }}>
            Religion
          </p>
          <input type="text" />
        </div>
        <div className="field1">
          <p
            style={{
              padding: "5px 0px",
            }}>
            Living in
          </p>
          <input type="text" />
        </div>
        <Link to="signin" className="find">
          <button className="find-btn">Find out</button>
        </Link>
      </div>
    </div>
  );
}

export default Features;
