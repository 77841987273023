import React from "react";
import "./Card2.css";

function Card2() {
  return (
    <div className="card2">
      <h2>100+</h2>
      <p style={{ textAlign: "center" }}>Users using shaadmani</p>
    </div>
  );
}

export default Card2;
