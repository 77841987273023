import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Image from "../../components/Image/Image";
import UserProfile from "../../components/User-Profile/UserProfile";
import { personalDetails } from "../../utils/api";
import "./socialLinks.css";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import Loading from "../../components/Loading/Loading";
import CnicComponent from "../../components/CnicComponent/CnicComponent";
import SocialPopup from "../../components/SocialPopup/SocialPopup";
import { FaFacebook, FaWhatsapp } from "react-icons/fa"; // Import Facebook and Whatsapp icons

function SocialLinks() {
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);

  const user = useSelector((state) => state.user?.user);

  const [image1, setImage1] = useState();
  const [image1Changed, setImage1Changed] = useState(false);

  const [image2, setImage2] = useState();
  const [image2Changed, setImage2Changed] = useState(false);

  const [image3, setImage3] = useState();
  const [image3Changed, setImage3Changed] = useState(false);

  const [image4, setImage4] = useState();
  const [image4Changed, setImage4Changed] = useState(false);

  const [image5, setImage5] = useState();
  const [image5Changed, setImage5Changed] = useState(false);

  const [image6, setImage6] = useState();
  const [image6Changed, setImage6Changed] = useState(false);

  const [cnicImageFront, setCnicImageFront] = useState();
  const [cnicImageFrontChanged, setCnicImageFrontChanged] = useState(false);

  const [cnicImageBack, setCnicImageBack] = useState();
  const [cnicImageBackChanged, setCnicImageBackChanged] = useState(false);

  const [profileImage, setProfileImage] = useState("");
  const [profileImageChanged, setProfileImageChanged] = useState(false);

  const [submitClicked, setSubmitClicked] = useState(false);

  const [loading, setLoading] = useState(false);

  const cloudinaryUploadImage = async (recSource) => {
    const form = new FormData();
    form.append("file", recSource);

    form.append("upload_preset", "shaadmani");
    form.append("cloud_name", "dtib2nmzi");
    const { data } = await axios.post(
      "https://api.cloudinary.com/v1_1/dtib2nmzi/image/upload",
      form,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    console.log("data", data.secure_url);
    return data.secure_url;
  };

  const handleSubmit = async (e) => {
    setSubmitClicked(true);
    setLoading(true);
    setIsLoading(false);

    e.preventDefault();
    let images = [null, null, null, null, null, null];
    let CNICimages = [null, null];
    let profile;

    if (image1Changed) {
      const source_URL_Image = await cloudinaryUploadImage(image1);
      images[0] = source_URL_Image;
      setImage1Changed(false);
    } else {
      images[0] = image1;
    }

    if (image2Changed) {
      const source_URL_Image = await cloudinaryUploadImage(image2);
      images[1] = source_URL_Image;
      setImage2Changed(false);
    } else {
      images[1] = image2;
    }

    if (image3Changed) {
      const source_URL_Image = await cloudinaryUploadImage(image3);
      images[2] = source_URL_Image;
      setImage3Changed(false);
    } else {
      images[2] = image3;
    }

    if (image4Changed) {
      const source_URL_Image = await cloudinaryUploadImage(image4);
      images[3] = source_URL_Image;
      setImage4Changed(false);
    } else {
      images[3] = image4;
    }

    if (image5Changed) {
      const source_URL_Image = await cloudinaryUploadImage(image5);
      images[4] = source_URL_Image;
      setImage5Changed(false);
    } else {
      images[4] = image5;
    }

    if (image6Changed) {
      const source_URL_Image = await cloudinaryUploadImage(image6);
      images[5] = source_URL_Image;
      setImage6Changed(false);
    } else {
      images[5] = image6;
    }

    if (cnicImageFrontChanged) {
      console.log("cnicImageFront", cnicImageFront);
      const source_URL_Image = await cloudinaryUploadImage(cnicImageFront);
      CNICimages[0] = source_URL_Image;
      setCnicImageFrontChanged(false);
    } else {
      CNICimages[0] = cnicImageFront;
    }
    if (cnicImageBackChanged) {
      const source_URL_Image = await cloudinaryUploadImage(cnicImageBack);
      CNICimages[1] = source_URL_Image;
      setCnicImageBackChanged(false);
    } else {
      CNICimages[1] = cnicImageBack;
    }

    if (profileImageChanged) {
      const source_URL_Image = await cloudinaryUploadImage(profileImage);
      profile = source_URL_Image;
      setProfileImageChanged(false);
    } else {
      profile = user?.profileImage;
    }

    personalDetails({
      images,
      CNICimages,
      id: user?._id,
      profileImage: profile,
    })
      .then(({ data }) => {
        console.log(data);
        setImage1(data.images[0]);
        setImage2(data.images[1]);
        setImage3(data.images[2]);
        setImage4(data.images[3]);
        setImage5(data.images[4]);
        setImage6(data.images[5]);

        setCnicImageFront(data.CNICimages[0]);
        setCnicImageBack(data.CNICimages[1]);
      })

      .catch((e) => console.log(e));
    setLoading(false);
  };

  const openFacebookProfile = () => {
    window.open(user.facebookLink, "_blank"); // This will open the Facebook profile in a new tab or window.
  };

  const openWhatsAppChat = () => {
    const url = `https://wa.me/${user.whatsappLink}?text=Hello%20from%20React.js`; // You can customize the text message here.
    window.open(url, "_blank");
  };
  useEffect(() => {
    if (user?.images) {
      setImage1(user?.images[0]);
      setImage2(user?.images[1]);
      setImage3(user?.images[2]);
      setImage4(user?.images[3]);
      setImage5(user?.images[4]);
      setImage6(user?.images[5]);

      setIsLoading(false);
    }
    if (user?.CNICimages) {
      setCnicImageFront(user?.CNICimages[0]);
      setCnicImageBack(user?.CNICimages[1]);
      setIsLoading(false);
    }
  }, [user]);
  console.log("first");
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div className="social-linkHeader">
            <div className="social-linkParent">
              <div className="social-linkChild1">
                <UserProfile
                  image={profileImage}
                  setImage={setProfileImage}
                  setChanged={setProfileImageChanged}
                />
              </div>
              <div className="social-linkChild2">
                <h3 className="social-linkHeading">
                  Add the social media link
                </h3>

                <div className="social-linkLogo">
                  <span onClick={() => setShow(true)}>
                    <Image onClick={() => setShow(true)} />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <SocialPopup show={show} setShow={setShow} />
          <div className="religion-details">
            <h3>Social Media Links</h3>
            <div className="social-details">
              <div className="social-detailsChild1">
                {user.facebookLink ? (
                  <div>
                    <span onClick={openFacebookProfile}>
                      <FaFacebook />
                      &nbsp; Open Your Facebook Profile{" "}
                    </span>
                  </div>
                ) : (
                  <div className="detailsChildError">
                    No Facebook Account Linked
                  </div>
                )}
              </div>

              <div className="social-detailsChild2">
                {user.whatsappLink ? (
                  <div>
                    <span onClick={openWhatsAppChat}>
                      <FaWhatsapp />
                      &nbsp; Your Whatsapp
                    </span>{" "}
                  </div>
                ) : (
                  <div className="detailsChildError">
                    No Whatsapp Number Added
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SocialLinks;
