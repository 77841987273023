import React from "react";
import "./Footer.css";

function Footer() {
  const email = "shaadmani@gmail.com";
  const phoneNumber = "03457849933";

  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer-section1">
          <div>
            <img
              src="../logo.png"
              alt=""
              height="50px"
              width="200px"
              style={{ marginTop: "1.5rem" }}
            />
            <p>
              Join shaadmani and meet the <br /> chosen one.
            </p>
          </div>
        </div>
        <div className="footer-section2">
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.8146 36.0412H16.4468V24.7915L9.82935 19.8284V34.0559C9.82935 35.1544 10.7194 36.0412 11.8146 36.0412Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M32.3289 36.0412H36.9611C38.0596 36.0412 38.9463 35.1511 38.9463 34.0559V19.8284L32.3289 24.7915"
                    fill="#34A853"
                  />
                  <path
                    d="M32.3289 16.1889V24.7916L38.9463 19.8285V17.1815C38.9463 14.7264 36.1438 13.3268 34.1818 14.7992"
                    fill="#FBBC04"
                  />
                  <path
                    d="M16.4468 24.7914V16.1887L24.3878 22.1445L32.3287 16.1887V24.7914L24.3878 30.7472"
                    fill="#EA4335"
                  />
                  <path
                    d="M9.82935 17.1815V19.8285L16.4468 24.7916V16.1889L14.5939 14.7992C12.6285 13.3268 9.82935 14.7264 9.82935 17.1815"
                    fill="#C5221F"
                  />
                </svg>
              </span>
              <a className="text2" href={`mailto:${email}`}>
                {email}
              </a>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 36.5002L14.2937 29.8368C12.8471 27.241 12.3875 24.212 12.9993 21.3055C13.611 18.3991 15.253 15.8101 17.624 14.0138C19.9949 12.2174 22.9359 11.3341 25.907 11.5261C28.8782 11.718 31.6804 12.9722 33.7993 15.0585C35.9182 17.1449 37.2117 19.9234 37.4426 22.8843C37.6734 25.8452 36.826 28.7898 35.0559 31.1778C33.2858 33.5658 30.7117 35.237 27.806 35.8847C24.9003 36.5324 21.8578 36.1132 19.2369 34.7041L12.5 36.5002ZM19.5618 32.2106L19.9784 32.4571C21.8768 33.5794 24.094 34.0439 26.2846 33.778C28.4753 33.5122 30.5162 32.531 32.0894 30.9874C33.6626 29.4438 34.6797 27.4245 34.9821 25.2441C35.2846 23.0637 34.8555 20.8448 33.7616 18.9331C32.6678 17.0214 30.9707 15.5243 28.9347 14.6751C26.8988 13.8259 24.6384 13.6723 22.5059 14.2383C20.3733 14.8043 18.4883 16.058 17.1447 17.8041C15.8011 19.5502 15.0743 21.6907 15.0776 23.8918C15.0758 25.7169 15.5818 27.5067 16.5393 29.062L16.8006 29.4916L15.7979 33.2108L19.5618 32.2106Z"
                    fill="#00D95F"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.6742 25.6027C29.43 25.4062 29.144 25.2679 28.8381 25.1983C28.5321 25.1287 28.2143 25.1297 27.9088 25.2012C27.4498 25.3914 27.1532 26.1099 26.8566 26.4691C26.794 26.5552 26.7021 26.6156 26.5981 26.639C26.4941 26.6623 26.3851 26.647 26.2916 26.5959C24.6112 25.9394 23.2026 24.7354 22.2947 23.1796C22.2172 23.0825 22.1806 22.9593 22.1924 22.8358C22.2043 22.7123 22.2637 22.5982 22.3582 22.5175C22.689 22.1909 22.9319 21.7863 23.0644 21.3412C23.0938 20.8503 22.9811 20.3612 22.7396 19.9324C22.5529 19.3313 22.1976 18.796 21.7156 18.3898C21.467 18.2783 21.1914 18.2409 20.922 18.2822C20.6526 18.3234 20.4009 18.4415 20.1973 18.6223C19.8439 18.9265 19.5633 19.3059 19.3763 19.7325C19.1893 20.1592 19.1007 20.6223 19.1169 21.0876C19.118 21.349 19.1512 21.6092 19.2157 21.8624C19.3797 22.471 19.6319 23.0523 19.9643 23.5882C20.2041 23.9986 20.4658 24.396 20.7481 24.7786C21.6659 26.0352 22.8194 27.1021 24.1449 27.9201C24.81 28.3358 25.5208 28.6738 26.2634 28.9274C27.0348 29.2762 27.8864 29.41 28.728 29.3148C29.2074 29.2424 29.6617 29.0536 30.0508 28.7649C30.44 28.4763 30.752 28.0967 30.9595 27.6595C31.0814 27.3954 31.1184 27.1001 31.0654 26.8142C30.9383 26.2296 30.1544 25.8845 29.6742 25.6027Z"
                    fill="#00D95F"
                  />
                </svg>
              </span>
              {/* <p className="text2">03457849933</p> */}
              <a className="text2" href={`https://wa.me/${phoneNumber}`}>
                {phoneNumber}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer2" style={{ padding: "10px" }}>
        <div className="lastline">
          <span>
            <svg
              width="20"
              height="21"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.86 16.5C9.86 15.3667 10.0867 14.38 10.54 13.54C10.9933 12.6867 11.62 12.0267 12.42 11.56C13.2333 11.0933 14.16 10.86 15.2 10.86C16.5467 10.86 17.6533 11.1867 18.52 11.84C19.4 12.4933 19.98 13.4 20.26 14.56H18.3C18.1133 13.8933 17.7467 13.3667 17.2 12.98C16.6667 12.5933 16 12.4 15.2 12.4C14.16 12.4 13.32 12.76 12.68 13.48C12.04 14.1867 11.72 15.1933 11.72 16.5C11.72 17.82 12.04 18.84 12.68 19.56C13.32 20.28 14.16 20.64 15.2 20.64C16 20.64 16.6667 20.4533 17.2 20.08C17.7333 19.7067 18.1 19.1733 18.3 18.48H20.26C19.9667 19.6 19.38 20.5 18.5 21.18C17.62 21.8467 16.52 22.18 15.2 22.18C14.16 22.18 13.2333 21.9467 12.42 21.48C11.62 21.0133 10.9933 20.3533 10.54 19.5C10.0867 18.6467 9.86 17.6467 9.86 16.5Z"
                fill="#333333"
              />
              <circle cx="15" cy="16" r="14.5" stroke="#333333" />
            </svg>
          </span>
          <p className="copyright">All rights reserved</p>
        </div>
        {/* <div style={{ width: "50%" }} className="lastline2">
          <div className="tag">
            <p>
              Powered By <br /> “Texinity Technologies”
            </p>
            <img src="../texinity.png" alt="" height="40px" width="70px" />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Footer;
