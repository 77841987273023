import React from "react";
import "./Card4.css";

function Card4() {
  return (
    <div className="card4">
      <h2>12</h2>
      <p>Feedbacks</p>
    </div>
  );
}

export default Card4;
