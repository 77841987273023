import { ErrorMessage } from "formik";
import React from "react";

const InputFieldSmp = ({
  name,
  type,
  classNameInput,
  classNameMain,
  value,
  onChange,
  placeholder,
  hasError,
  errorMessage,
  text,
}) => (
  <div className={classNameMain}>
    <input
      className={`input ${classNameInput}`}
      type={type}
      id={name}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    {hasError && (
      <div>
        <p
          style={{ color: "red", margin: " 1px 0px 1px 1px", fontSize: "15px" }}
        >
          {errorMessage}
        </p>
      </div>
    )}
  </div>
);

export default InputFieldSmp;
