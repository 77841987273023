import React from "react";
import "./Card3.css";

function Card3() {
  return (
    <div className="card3">
      <h2>25</h2>
      <p>Shares</p>
    </div>
  );
}

export default Card3;
