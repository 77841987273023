import axios from "axios";

export const getAllCountries = async (setAllCountries) => {
  try {
    const { data } = await axios.get(
      "https://countriesnow.space/api/v0.1/countries"
    );
    setAllCountries(data.data.map((country) => country.country));
  } catch (error) {
    console.log(error);
  }
};

export const getCities = async (country, setCities) => {
  try {
    const { data } = await axios.post(
      "https://countriesnow.space/api/v0.1/countries/cities",
      { country }
    );
    setCities(data.data.sort());
  } catch (error) {
    console.log(error);
  }
};
