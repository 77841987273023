import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: "https://shad-server.uc.r.appspot.com",
  // baseURL: "http://localhost:8080",
  // baseURL: "http://192.168.18.93:8080",
  // baseURL: "http://172.20.10.3:8080"
  // baseURL: "http://192.168.1.107:8080",
});

API.interceptors.request.use((req) => {
  if (Cookies.get("shaadmani-token")) {
    req.headers.authorization = `Bearer ${Cookies.get("shaadmani-token")}`;
  }
  return req;
});

export const createAccount = (userData) => API.post(`user/signup`, userData);
export const login = (userData) => API.post(`user/signIn`, userData);
export const getAllUsers = () => API.get(`user/getAllUsers`);
export const getUser = () => API.get(`user/getUser`);
export const personalDetails = (details) =>
  API.patch(`user/personal-detail`, details);
export const religionDetails = (details) =>
  API.patch(`user/religious-detail`, details);
export const addspouseDetails = (details) =>
  API.post(`spouse/spouseDetails`, details);

export const getspouseDetails = () => API.get(`spouse/get-spouse-details`);

export const getspouseDetailById = () =>
  API.get(`spouse/get-SpouseDetail-By-Id`);

export const updateSpouseDetail = (detail) =>
  API.patch(`spouse/update-spouse-detail`, detail);

export const getEventByIdApi = (eventId) =>
  API.get(`event/event-by-id/${eventId}`);
export const createEvent = (eventData) =>
  API.post(`event/create-event`, eventData);

export const getAllEvents = (eventData) => API.get(`event`, eventData);

export const bookEventAPI = (eventId) =>
  API.patch(`event/book-event/${eventId}`);

export const addpayment = (eventId, paymentscreenShot) =>
  API.post(`payment/add-payment/${eventId}`, paymentscreenShot);

// forget password
export const forgotPassword = (email) =>
  API.post(`user/forgot-password`, email);
export const checkToken = (token) => API.post(`user/check-token`, token);
export const resetPassword = (password) =>
  API.post(`user/reset-password`, password);
//.....

export const paymentStatus = () => API.get(`payment/payment-status`);
