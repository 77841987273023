import { USER_BEGIN, USER_CLEAR, USER_ERROR, USER_SUCCESS } from "./action";
const initialState = {
  user: {},
  error: null,
  loading: true,
};
const userReducer = (state = initialState, action) => {
  const { type, user, error } = action;
  switch (type) {
    case USER_BEGIN:
      return { ...state, error: null, loading: true };
    case USER_SUCCESS:
      return { ...state, error: null, user, loading: false };
    case USER_ERROR:
      return { ...state, error, loading: false, user: null };
    case USER_CLEAR:
      return { error: null, user: null, loading: false };

    default:
      return state;
  }
};
export default userReducer;
