import React from "react";
import QualificationDetailsForm from "../../components/Forms/QualificationDetailsForm";

function QualificationDetails() {
  return (
    <div>
      <QualificationDetailsForm />
    </div>
  );
}

export default QualificationDetails;
