import React, { useEffect, useState } from "react";
import "./Forms.css";
import validationSchema from "../../utils/schemas/validationSchema";
import { Formik, Form } from "formik";
import { RotatingLines } from "react-loader-spinner";
import InputField from "../FormComponents/InputField";
import SelectField from "../FormComponents/SelectField";
import { useDispatch, useSelector } from "react-redux";
import { personalDetails } from "../../utils/api";
import {
  createUserDetails,
  getUserAction,
} from "../../redux/user/actionCreator";
import { useNavigate } from "react-router-dom";

const initialValues = {
  educationLevel: "",
  language: "",
  jobTitle: "",
  employementStatus: "",
};

function QualificationDetailsForm() {
  const [details, setDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const handlechange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    console.log(details);
  };

  const handleSelect = (fieldname, selectedOption) => {
    setDetails({ ...details, [fieldname]: selectedOption.value });
  };

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAction());
  }, [dispatch]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    dispatch(createUserDetails({ ...details, id: user._id }));

    navigate("/spouse-detail");
    setLoading(false);
  };

  useEffect(() => {
    if (user.user?.jobTitle) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <div className="formContainer">
      <div className="mainDivForm">
        <h1>Qualification / Profession</h1>
        <Formik
          initialValues={details}
          enableReinitialize={true}
          validationSchema={validationSchema.qualificationDetails}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="maindivforDivision">
                <div className="formSeperation">
                  <SelectField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formSelect"
                    label="Degree / Education level"
                    name="educationLevel"
                    placeholder="Your education ?"
                    onChange={(selectedOption) =>
                      handleSelect("educationLevel", selectedOption)
                    }
                    options={[
                      { value: "Matric", label: "Matric" },
                      { value: "Intermediate", label: "Intermediate" },
                      {
                        value: "Bachlors of Science (BS)",
                        label: "Bachlors of Science (BS)",
                      },
                      {
                        value: "Master of Science (MS)",
                        label: "Master of Science (MS)",
                      },
                      {
                        value: "Doctor of Philosophy (PhD)",
                        label: "Doctor of Philosophy (PhD)",
                      },
                    ]}
                    hasError={touched.educationLevel && errors.educationLevel}
                    errorMessage={errors.educationLevel}
                  />
                  <SelectField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formSelect"
                    label="Employment"
                    name="employementStatus"
                    placeholder="Your employement status ?"
                    onChange={(selectedOption) =>
                      handleSelect("employementStatus", selectedOption)
                    }
                    options={[
                      { value: "Employeed", label: "Employeed" },
                      { value: "UnEmployeed", label: "UnEmployeed" },
                      { value: "Retired", label: "Retired" },
                      { value: "Redundant", label: "Redundant" },
                      { value: "Homemaker", label: "Homemaker" },
                    ]}
                    hasError={
                      touched.employementStatus && errors.employementStatus
                    }
                    errorMessage={errors.employementStatus}
                  />
                </div>
                <div className="formSeperation">
                  <SelectField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formSelect"
                    label="Language"
                    name="language"
                    placeholder="Language you speak ?"
                    onChange={(selectedOption) =>
                      handleSelect("language", selectedOption)
                    }
                    options={[
                      { value: "English", label: "English" },
                      { value: "Urdu", label: "Urdu" },
                      { value: "Punjabi", label: "Punjabi" },
                      { value: "Pashto", label: "Pashto" },
                      { value: "Saraiki", label: "Saraiki" },
                    ]}
                    hasError={touched.language && errors.language}
                    errorMessage={errors.language}
                  />
                  <InputField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formInput"
                    label="Job Title"
                    value={details.jobTitle}
                    name="jobTitle"
                    type="text"
                    placeholder="You're currently working as ?"
                    onChange={handlechange}
                    hasError={touched.jobTitle && errors.jobTitle}
                    errorMessage={errors.jobTitle}
                  />
                </div>
              </div>
              <div className="buttonDiv">
                <button className="formButton" type="submit">
                  {loading ? (
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={true}
                    />
                  ) : (
                    "save"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default QualificationDetailsForm;
