import React, { useState } from "react";
import "./ForgetPasswordPopup.css";
import { forgotPassword } from "../../utils/api";
import { RotatingLines } from "react-loader-spinner";

function ForgetPasswordPopup({ setCurrentComponent, email, setEmail }) {
  // const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (email === "") {
        console.log("valid email");
        return;
      }
      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

      if (reg.test(email) === false) {
        console.log("Invalid email");

        return;
      }
      const res = await forgotPassword({ email });
      setCurrentComponent("OTPCode");
      console.log("res", res);
      setLoading(false);
    } catch (error) {
      if (error.response.status == 404) {
        setError("Email Not Found");
        return;
      }
      if (error.response.status == 500) {
        setError("Email Not Found");
        return;
      }
      console.log(error);
    }
  };

  console.log("email", email);
  return (
    <div className="forget-password-main">
      <p className="forget-password-text">Forgot Password?</p>
      <input
        type="email"
        placeholder="Enter your email address"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        value={email}
      />
      <p className="error-text">{error}</p>
      <p className="link-text">
        A link will be sent to your email to reset your password
      </p>
  
      <button className="sent-btn" onClick={handleSubmit}>
        {loading ? (
          <RotatingLines
            strokeColor="white"
            strokeWidth="5"
            animationDuration="0.75"
            width="15"
            visible={true}
          />
        ) : (
          "Sent"
        )}
      </button>
    </div>
  );
}

export default ForgetPasswordPopup;
