import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

function AuthLayout() {
  const auth = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (auth.token) {
      navigate("dashboard");
      // navigate("/personal-details");
    }
  }, [auth, navigate]);

  return <Outlet />;
}

export default AuthLayout;
