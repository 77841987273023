import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { personalDetails } from "../../utils/api";
import "./Dashboard.css";
import { useDispatch } from "react-redux";
import { getUserAction } from "../../redux/user/actionCreator";
import ShowProfileImg from "../../components/ShowProfileImg/ShowProfileImg";
import Loading from "../../components/Loading/Loading";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
function Dashboard() {
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.user);
  const [onEdit, setOnEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editBtnValue, setEditBtnValue] = useState("Edit");
  const [height, setHeight] = useState({
    feet: user?.height?.feet,
    inches: user?.height?.inches,
  });
  console.log("first", user);

  useEffect(() => {
    setUpdateDetails({
      gender: user?.gender,
      weight: user?.weight,
      HeightFeet: user?.height?.feet,
      feet: user?.height?.feet,
      heightInches: user?.height?.inches,
      profession: user?.profession,
      phoneNum: user?.phoneNum,
      noOfSiblings: user?.noOfSiblings,
      employementStatus: user?.employementStatus,
      nationality: user?.nationality,
      bio: user?.bio,
      location: user?.location,
      city: user?.city,
      alcohol: user?.alcohol,
      smoker: user?.smoker,
      adminRemarks: user?.adminRemarks,
      nextOfKin: user?.nextOfKin,
    });
    setIsLoading(false);
  }, [user, refresh]);
  console.log("user", user);
  const [updateDetails, setUpdateDetails] = useState({
    user,
  });

  const update = async (e) => {
    setLoading(true);

    personalDetails({ ...updateDetails, id: user?._id, height })
      .catch((e) => console.log(e))
      .then(() => {
        setRefresh((r) => !r);
        dispatch(getUserAction());

        setLoading(false);
        Swal.fire({
          title: "Data Updated Successfully !",
          icon: "success",
          confirmButtonColor: "#D21285",
          confirmButtonText: "OK",
          iconColor: "#D21285",
          background: "#FFE4F4",
          customClass: {
            container: "my-swal-border",
          },
        });
      });
  };

  const onEditfTN = (e) => {
    e.preventDefault();
    onEdit ? setEditBtnValue("Edit") : setEditBtnValue("Update");
    if (onEdit) {
      update();
    }
    setOnEdit((val) => !val);
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div className="main-Contt">
            <div className="main-Contt-Pic">
              <h2 className="main-Contt-Heading">{user?.userName}</h2>
              <ShowProfileImg />
            </div>
            {user?.adminRemarks && (
              <div className="main-Contt-Remarks">
                <h3>Remarks By Shaadmani Admin</h3>
                <p>{user.adminRemarks}</p>
              </div>
            )}
          </div>

          <div className="bio">
            <h3>About/Bio</h3>
            {onEdit ? (
              <textarea
                name=""
                id=""
                cols="100"
                rows="5"
                value={updateDetails?.bio}
                onChange={(e) => {
                  setUpdateDetails({ ...updateDetails, bio: e.target.value });
                }}
              />
            ) : (
              <p>{user?.bio}</p>
            )}
          </div>
          <form action="">
            <div className="edit-btn">
              <button onClick={onEditfTN}>{editBtnValue}</button>
            </div>
            <div className="show-details">
              <div className="show-details1">
                <div className="sub-details1">
                  <p>Gender</p>
                  <p>Weight</p>
                  <p>Height</p>
                  <p>Phone no.</p>
                  <p>siblings</p>
                  <p>Next of Kin</p>
                  <p>City</p>
                </div>
                <div className="sub-details2">
                  {onEdit ? (
                    <input
                      type="text"
                      value={updateDetails?.gender}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          gender: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.gender}</p>
                  )}
                  {onEdit ? (
                    <input
                      type="number"
                      value={updateDetails?.weight}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          weight: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.weight}</p>
                  )}
                  {onEdit ? (
                    <div className="select-height" style={{ display: "flex" }}>
                      <input
                        type="number"
                        style={{ width: "50%" }}
                        value={height?.feet}
                        step="any"
                        onChange={(e) => {
                          setHeight({
                            ...height,
                            feet: e.target.value,
                          });
                        }}
                      />
                      <input
                        type="number"
                        style={{ width: "50%" }}
                        value={height?.inches}
                        step="any"
                        onChange={(e) => {
                          setHeight({
                            ...height,
                            inches: e.target.value,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <p>
                      {user?.height?.feet}'{user?.height?.inches}
                    </p>
                  )}
                  {onEdit ? (
                    <input
                      type="number"
                      value={updateDetails?.phoneNum}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          phoneNum: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.phoneNum}</p>
                  )}
                  {onEdit ? (
                    <input
                      type="number"
                      value={updateDetails?.noOfSiblings}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          noOfSiblings: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.noOfSiblings}</p>
                  )}
                  {onEdit ? (
                    <input
                      type="number"
                      value={updateDetails?.nextOfKin}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          nextOfKin: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.nextOfKin}</p>
                  )}
                  {onEdit ? (
                    <input
                      type="text"
                      value={updateDetails?.city}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          city: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.city}</p>
                  )}
                </div>
              </div>
              <div className="show-details1">
                <div className="sub-details1">
                  <p>Employment</p>
                  <p>Location</p>
                  <p>Nationality</p>
                  <p>Profession</p>
                  <p>Alcohol</p>
                  <p>Smoker</p>
                </div>
                <div className="sub-details2">
                  {onEdit ? (
                    <input
                      type="text"
                      value={updateDetails?.employementStatus}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          employementStatus: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.employementStatus}</p>
                  )}
                  {onEdit ? (
                    <input
                      type="text"
                      value={updateDetails?.location}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          location: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.location}</p>
                  )}
                  {onEdit ? (
                    <input
                      type="text"
                      value={updateDetails?.nationality}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          nationality: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.nationality}</p>
                  )}

                  {onEdit ? (
                    <input
                      type="text"
                      value={updateDetails?.profession}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          profession: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.profession}</p>
                  )}
                  {onEdit ? (
                    <input
                      type="text"
                      value={updateDetails?.alcohol}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          alcohol: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.alcohol ? "Yes" : "No"}</p>
                  )}
                  {onEdit ? (
                    <input
                      type="text"
                      value={updateDetails?.smoker}
                      onChange={(e) => {
                        setUpdateDetails({
                          ...updateDetails,
                          smoker: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <p>{user?.smoker ? "Yes" : "No"}</p>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
