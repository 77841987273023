export const USER_BEGIN = "USER_BEGIN";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";
export const USER_CLEAR = "USER_CLEAR";

export const userBegin = () => {
  return {
    type: USER_BEGIN,
  };
};

export const userSuccess = (user) => {
  return {
    type: USER_SUCCESS,
    user,
  };
};

export const userError = (error) => {
  return {
    type: USER_ERROR,
    error,
  };
};

export const userClear = () => {
  return {
    type: USER_CLEAR,
  };
};
