import { getUser, personalDetails } from "../../utils/api";
import { userBegin, userError, userSuccess } from "./action";

export const getUserAction = () => {
  return async (dispatch) => {
    try {
      dispatch(userBegin());
      const { data } = await getUser();
      dispatch(userSuccess(data));
    } catch (error) {
      console.log(error);
      dispatch(userError(error));
    }
  };
};

export const createUserDetails = (form) => {
  return async (dispatch) => {
    try {
      dispatch(userBegin());
      const { data } = await personalDetails(form);
      console.log("user action creator data", data);
      dispatch(userSuccess(data));
    } catch (error) {
      dispatch(userError(error));
    }
  };
};
