import React from "react";
import "./Card.css";

function Card() {
  return (
    <div className="card">
      <h2>50</h2>
      <p style={{ textAlign: "center" }}>Number of positive reviews</p>
    </div>
  );
}

export default Card;
