import React, { useState } from "react";
import "./ReviewCard.css";

function ReviewCard() {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  // const card = [1, 2, 3];
  return (
    <div className="question1">
      <div className="questions" onClick={toggle}>
        <p>What is the purpose of this marriage portal website?</p>
        <div className="remove-icon">
          <svg
            width="22"
            height="22"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="16"
              cy="16"
              r="12"
              stroke="black"
              stroke-opacity="0.8"
              stroke-width="2"
            />
            <path
              d="M10 16H22"
              stroke="black"
              stroke-opacity="0.8"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
      {show ? (
        <div className="answer">
          <p>
            The purpose of shaadmani is to provide a platform for individuals
            who are seeking to find a life partner. Our website offers a
            user-friendly interface where users can create a profile, fill the
            form and shaadmani team will search for the partner for you. We
            understand that finding a suitable partner can be a daunting task,
            and we aim to simplify the process by providing a safe and secure
            platform where users can connect with like-minded individuals who
            share similar values, interests, and goals. Our goal is to help
            people find their life partner and build a fulfilling and happy life
            together.
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default ReviewCard;
