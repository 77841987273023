import React, { useEffect, useState } from "react";
import "./Forms.css";
import validationSchema from "../../utils/schemas/validationSchema";
import { Formik, Form } from "formik";
import SelectField from "../FormComponents/SelectField";
import InputField from "../FormComponents/InputField";

import { useDispatch, useSelector } from "react-redux";
import { personalDetails } from "../../utils/api";
import "sweetalert2/dist/sweetalert2.min.css";
import { getUserAction } from "../../redux/user/actionCreator";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

const initialValues = {
  religion: "",
  convertReligion: "",
  practicing: "",
  praying: "",
};

function ReligiousDetailsForm() {
  const [details, setDetails] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const handleSelect = (fieldname, selectedOption) => {
    setDetails({ ...details, [fieldname]: selectedOption.value });
  };

  const user = useSelector((state) => state.user?.user);
  console.log("user", user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAction());
  }, [dispatch]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    personalDetails({
      ...details,
      id: user?._id,
    })
      .then(({ data }) => {
        console.log("data: 76", data);
        navigate("/interest-detail");
      })
      .catch((e) => console.log(e));
  };
  console.log("details", details);

  useEffect(() => {
    if (user.user?.religion) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <div className="formContainer">
      <div className="mainDivForm">
        <h1>Religious Details</h1>

        <Formik
          initialValues={details}
          enableReinitialize={true}
          validationSchema={validationSchema.religiousDetails}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="maindivforDivision">
                <div className="formSeperation">
                  <SelectField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formSelect"
                    label="Religion"
                    name="religion"
                    placeholder="Your Religion"
                    onChange={(selectedOption) =>
                      handleSelect("religion", selectedOption)
                    }
                    options={[
                      { value: "Muslim", label: "Muslim" },
                      { value: "Spirtual", label: "Spirtual" },
                      { value: "Christian", label: "Christian" },
                      { value: "Jewism", label: "Jewism" },
                      { value: "Janism", label: "Janism" },
                      { value: "Others", label: "Others" },
                      {
                        value: "Prefer not to say",
                        label: "Prefer not to say",
                      },
                    ]}
                    hasError={touched.religion && errors.religion}
                    errorMessage={errors.religion}
                  />
                  <SelectField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formSelect"
                    label="Convert / Revert"
                    name="convertReligion"
                    placeholder="Convert / Revert"
                    onChange={(selectedOption) =>
                      handleSelect("convertReligion", selectedOption)
                    }
                    options={[
                      { value: true, label: "Convert" },
                      { value: false, label: "Revert" },
                    ]}
                    hasError={touched.convertReligion && errors.convertReligion}
                    errorMessage={errors.convertReligion}
                  />
                </div>
                <div className="formSeperation">
                  <SelectField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formSelect"
                    label="Practicing"
                    name="practicing"
                    placeholder="Practicing"
                    onChange={(selectedOption) =>
                      handleSelect("practicing", selectedOption)
                    }
                    options={[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ]}
                    hasError={touched.practicing && errors.practicing}
                    errorMessage={errors.practicing}
                  />
                  <SelectField
                    classNameMain="inputMain"
                    classNameLabel="formLabel"
                    classNameInput="formSelect"
                    label="Praying"
                    name="praying"
                    placeholder="Praying ?"
                    onChange={(selectedOption) =>
                      handleSelect("praying", selectedOption)
                    }
                    options={[
                      { value: "Always", label: "Always" },
                      { value: "Often", label: "Often" },
                      { value: "Usually", label: "Usually" },
                      { value: "Never Prays", label: "Never Prays" },
                    ]}
                    hasError={touched.praying && errors.praying}
                    errorMessage={errors.praying}
                  />
                </div>
              </div>
              <div className="buttonDiv">
                <button className="formButton" type="submit">
                  {loading ? (
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="15"
                      visible={true}
                    />
                  ) : (
                    "save"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ReligiousDetailsForm;
