import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getEventByIdApi, paymentStatus } from "../../utils/api/index";
import "./EventDetails.css";
import EventPopup from "../../components/EventPopup/EventPopup";
import { useSelector } from "react-redux";

function EventDetails() {
  const params = useParams();
  const [show, setShow] = useState(false);
  const [eventData, setEventData] = useState([]);
  const userID = useSelector((state) => state.user?.user?._id);
  const user = useSelector((state) => state.user?.user);
  const [isApproved, setisApproved] = useState("");
  const navigate = useNavigate();

  console.log("is approved", isApproved);

  const eventID = params.Id;
  useEffect(() => {
    const eventData = getEventByIdApi(eventID)
      .then(({ data }) => {
        console.log("dataa 30", data.data);
        setEventData(data.data);
        setisApproved(data.paymentStatus);
      })
      .catch((e) => console.log(e));
  }, [eventID, show]);
  console.log("event data", eventData);
  const guestsId = eventData.guestsId;
  console.log("guest id", eventData.guestsId);

  return (
    <div>
      <div
        style={{ cursor: "pointer", marginTop: "1rem", marginLeft: "2rem" }}
        onClick={() => navigate(-1)}
      >
        <svg
          width="12"
          height="16"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 1L2 7L8 13" stroke="#D21285" stroke-width="2" />
        </svg>
      </div>
      <div className="event-loc">
        <h2>{eventData.cityName} </h2>
      </div>
      <div className="event-pic">
        <img
          src={eventData.eventThumbnail}
          alt="error"
          height="100%"
          width="100%"
        />
      </div>
      <div className="set-date">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p>Date</p>
          <p className="date-text">{eventData.eventDate}</p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <p>Time</p>
          <p className="date-text">{eventData.eventTime}</p>
        </div>
      </div>
      <div className="events-btn">
        {guestsId &&
          userID &&
          (guestsId.includes(userID) ? (
            <div className="pending-btn">{isApproved}</div>
          ) : (
            <button className="btn1" onClick={() => setShow(true)}>
              Book Event
            </button>
          ))}
      </div>
      <EventPopup show={show} setShow={setShow} eventId={params.Id} />
    </div>
  );
}

export default EventDetails;
