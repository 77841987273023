import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/auth/action";
import "./Sidebar.css";
import { UilAngleLeft } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";

function Sidebar() {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const dispatch = useDispatch();
  const logoutHandle = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className={`sidebar ${open ? "sidebar1" : "hide-sidebar"}`}>
        <div className="image">
          <img
            src="/logo.png"
            alt="logo"
            style={{ width: "180px" }}
            className={`image-logo ${open ? "image1" : ""}`}
          />
        </div>
        <div className="list">
          <ul onClick={() => setOpen(false)}>
            <Link
              to="/dashboard"
              style={{ textDecoration: "none", color: "white" }}
            >
              <li>Home</li>
            </Link>
            <Link
              to="spouse-requirements"
              style={{ textDecoration: "none", color: "white" }}
            >
              <li>Spouse requirements</li>
            </Link>

            <Link
              to="pictures/multimedia"
              style={{ textDecoration: "none", color: "white" }}
            >
              <li>Picture / Multimedia</li>
            </Link>
            {/* // ) : null} */}

            <Link
              to="qualification"
              style={{ textDecoration: "none", color: "white" }}
            >
              <li>Qualification / Profession</li>
            </Link>
            <Link
              to="religious-details"
              style={{ textDecoration: "none", color: "white" }}
            >
              <li>Religious details </li>
            </Link>
            <Link
              to="interests"
              style={{ textDecoration: "none", color: "white" }}
            >
              <li>Interests</li>
            </Link>
            <Link
              to="events"
              style={{ textDecoration: "none", color: "white" }}
            >
              <li>Events</li>
            </Link>
            <Link
              to="social-links"
              style={{ textDecoration: "none", color: "white" }}
            >
              <li>Social Links</li>
            </Link>
          </ul>
        </div>
        <div className="logout-btn">
          <button onClick={logoutHandle}>logout</button>
        </div>
      </div>
      <div className="arrow" onClick={toggle}>
        <UilAngleLeft className="arrow-icon" />
      </div>
    </>
  );
}

export default Sidebar;
