import React from "react";
import SpouseRequirementsForm from "../../components/Forms/SpouseRequirementsForm";

function SpouseRequiremts() {
  return (
    <div>
      <SpouseRequirementsForm />
    </div>
  );
}

export default SpouseRequiremts;
