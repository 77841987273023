import "./Signin.css";
import SigninForm from "../../components/SigninForm/SigninForm";
import ForgetPasswordPopup from "../../components/ForgerPasswordPopup/ForgetPasswordPopup";
import { useState } from "react";
import OTPCode from "../../components/OTPCode.js/OTPCode";
import ResetPassword from "../../components/ResetPassword/ResetPassword";

function Signin() {
  const [currentComponent, setCurrentComponent] = useState("SigninForm");
  const [email, setEmail] = useState("");

  return (
    <div className="container-1">
      <div className="image-side-1">
        <img src="../login1.png" alt="error" />
      </div>
      <div className="form-page1">
        {(() => {
          switch (currentComponent) {
            case "SigninForm":
              return (
                <SigninForm
                  setCurrentComponent={setCurrentComponent}
                  currentComponent={currentComponent}
                />
              );
            case "ForgetPasswordPopup":
              return (
                <ForgetPasswordPopup
                  setCurrentComponent={setCurrentComponent}
                  currentComponent={currentComponent}
                  email={email}
                  setEmail={setEmail}
                />
              );
            case "OTPCode":
              return (
                <OTPCode
                  setCurrentComponent={setCurrentComponent}
                  currentComponent={currentComponent}
                  email={email}
                />
              );
            default:
              return (
                <ResetPassword
                  setCurrentComponent={setCurrentComponent}
                  currentComponent={currentComponent}
                  email={email}
                />
              );
          }
        })()}
      </div>
    </div>
  );
}

export default Signin;
