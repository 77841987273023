import React, { useState } from "react";
import "./OTPCode.css";
import { checkToken } from "../../utils/api";
import { RotatingLines } from "react-loader-spinner";

function OTPCode({ setCurrentComponent, email }) {
  const [otpCode, setOtpCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  console.log("otp page mail", email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!otpCode) {
      setError("Enter Your Code First");
      return;
    }

    try {
      const res = await checkToken({
        token: otpCode,
        userEmail: email,
      });
      console.log("res", res);
      setLoading(false);
      setCurrentComponent("ResetPassword");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="otp-main" >
      <h1>Check your mail</h1>
      <p>We just sent an OTP to your entered email address</p>
      <div className="otp-bars">
        <p>Enter Otp</p>
        <input
          placeholder="0 0 0 0"
          type="text"
          onChange={(e) => {
            setOtpCode(e.target.value);
          }}
        />
        <p style={{ color: "red", fontWeight: "bold" }}>{error}</p>
        <p>Didnt get a code? Resend</p>
        <button className="otp-verify" onClick={handleSubmit}>
          {loading ? (
            <RotatingLines
              strokeColor="white"
              strokeWidth="5"
              animationDuration="0.75"
              width="15"
              visible={true}
            />
          ) : (
            "Verify OTP"
          )}
        </button>
        {/* // )} */}
      </div>
    </div>
  );
}

export default OTPCode;
