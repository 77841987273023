import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../redux/auth/actionCreator";
import { Link } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { Formik, Form } from "formik";
import validationSchema from "../../utils/schemas/validationSchema";
import InputFieldSmp from "../../components/FormComponents/InputFieldSmp";
import { UilEye } from "@iconscout/react-unicons";
import { UilEyeSlash } from "@iconscout/react-unicons";
const initialValues = {
  email: "",
  password: "",
};

function SigninForm({ setCurrentComponent, currentComponent }) {
  const [details, setDetails] = useState(initialValues);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const auth = useSelector((state) => state.auth);

  const handlechange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    dispatch(signin(details));
  };

  useEffect(() => {
    if (auth.err) {
      if (auth.err.response.status == "404") {
        setError("You have entered an invalid email");
      }
      if (auth.err.response.status == "400") {
        setError("You have entered an invalid password");
      }
    }
  }, [auth]);
  console.log(auth.err);
  return (
    <div className="main-form1">
      <h2>Welcome Back To Login!</h2>
      <Formik
        initialValues={details}
        enableReinitialize={true}
        validationSchema={validationSchema.signIn}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form className="form-1">
            <InputFieldSmp
              classNameMain=""
              classNameInput="form-input"
              value={details.email}
              name="email"
              type="email"
              placeholder="Enter your email"
              onChange={handlechange}
              hasError={touched.email && errors.email}
              errorMessage={errors.email}
            />
            <div style={{ position: "relative" }}>
              <input
                className="form-input"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                onChange={handlechange}
                name="password"
                value={details.password}
                style={{ border: error ? "3px solid red" : null }}
              />
              <div
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: "absolute",
                  float: "right",
                  top: "0",
                  right: "0",
                  marginTop: "22px",
                  marginRight: "5px",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
              >
                {showPassword ? (
                  <UilEye style={{ fill: "#00000057", width: "17px" }} />
                ) : (
                  <UilEyeSlash style={{ fill: "#00000057", width: "17px" }} />
                )}
              </div>
            </div>

            {/* <InputFieldSmp
              classNameMain=""
              classNameInput="form-input"
              value={details.password}
              name="password"
              type="password"
              placeholder="Enter your password"
              onChange={handlechange}
              hasError={touched.password && errors.password}
              errorMessage={errors.password}
              text="xvscvscvhv"
            /> */}
            {error && (
              <p
                style={{
                  fontSize: "14px",
                  color: "red",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                {error}
              </p>
            )}
            <div className="line">
              <div className="line-checkbox">
                <input type="checkbox" />
                <p>Remember Me</p>
              </div>

              {currentComponent === "SigninForm" && (
                <button
                  style={{ border: "none" }}
                  onClick={() => setCurrentComponent("ForgetPasswordPopup")}
                >
                  <p>Forgot Password?</p>
                </button>
              )}
            </div>
            <button className="btn" type="submit">
              {auth.loading ? (
                <RotatingLines
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              ) : (
                "Login"
              )}
            </button>
            <p
              style={{
                textAlign: "center",
                fontSize: "12px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              Don't have an account?{" "}
              <Link to="/signup">
                <span>Sign up Now</span>
              </Link>
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SigninForm;
