import React, { useState } from "react";

function ReviewCard3() {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  return (
    <div className="question1">
      <div className="questions" onClick={toggle}>
        <p>How can I communicate with a member on this website?</p>
        <div className="remove-icon" onClick={toggle}>
          <svg
            width="22"
            height="22"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="16"
              cy="16"
              r="12"
              stroke="black"
              stroke-opacity="0.8"
              stroke-width="2"
            />
            <path
              d="M10 16H22"
              stroke="black"
              stroke-opacity="0.8"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
      {show ? (
        <div className="answer">
          <p>
            You can't communicate with the partner directly you can make account
            fill the form and shaadmani will search for your partner and they
            will arrange events for you where you can meet your partner if you
            have any query or any problem you can contact shaadmani members
            directly and also on our other social media platform
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default ReviewCard3;
