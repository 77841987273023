import React, { useState } from "react";
import "./ResetPassword.css";
import { resetPassword } from "../../utils/api";
import { RotatingLines } from "react-loader-spinner";

function ResetPassword({ email, setCurrentComponent }) {
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  console.log("reset pass page email", email);

  const handleSend = async () => {
    setLoading(true);
    if (password === "") {
      setError("Please Enter password");

      return;
    }
    if (confirmPassword === "") {
      setError("Please Enter confirm password");
      return;
    }
    if(!password === confirmPassword) {
       setError("password and confirm password must be matched");
    }
    if (password === confirmPassword) {
      try {
        const res = await resetPassword({
          password,
          email,
        });
        setLoading(false);
        // navigation.navigate('ResetSuccessfully');
        alert("Password is updated successfully!");
        console.log("resssss", res);
        setCurrentComponent("SigninForm")
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div className="reset-password-main">
      <h2>Reset your password</h2>
      <p>
        Here’s a tip: Use a combination of numbers, uppercase, lowercase and
        special characters
      </p>
      <input
        type="password"
        placeholder="Enter new password"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <p style={{color: "red", fontWeight:"bold"}}>{error}</p>
      <input
        type="password"
        placeholder="Enter confirm password"
        onChange={(e) => {
          setconfirmPassword(e.target.value);
        }}
      />
      <p style={{color: "red", fontWeight:"bold"}}>{error}</p>
      <button className="reset" onClick={handleSend}>
        {loading ? (
          <RotatingLines
            strokeColor="white"
            strokeWidth="5"
            animationDuration="0.75"
            width="15"
            visible={true}
          />
        ) : (
          "Reset Password"
        )}{" "}
      </button>
    </div>
  );
}

export default ResetPassword;
